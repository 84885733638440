import React from 'react';
import allianceLogo from '../../Asset/Alliance_maladies_rares.svg';
import Troisvies from '../../Asset/troisvies.jpg';
import EAlogo from '../../Asset/Logo-EA.jpg';
import Trouvea from '../../Asset/trouvea.jpg';
import FranceMutuelle from '../../Asset/france-mutuelle.jpg';
import BienEtre from '../../Asset/bien-etre.jpg';
import AVClogo from '../../Asset/AVC.png';
import DYSlogo from '../../Asset/dys.jpeg';
import unafam from '../../Asset/unafam.svg';
import Apf from '../../Asset/apf.svg';
import sosAmitie from '../../Asset/sos_amitier.png';
import secoursPopulaire from '../../Asset/secour-populaire.svg';
import hadParis from '../../Asset/had.png';
import cap from '../../Asset/cap.png';
import evolia from '../../Asset/evolia.webp';
import { Helmet } from 'react-helmet';
import { useColorMode } from '@chakra-ui/react'; // Import useColorMode

const NosPartenaires = () => {
    const { colorMode } = useColorMode(); // Get the current color mode

    const partenaires = [
        {
            nom: 'Alliance Maladies Rares',
            description: 'Alliance Maladies Rares regroupe des associations de patients pour améliorer la prise en charge des maladies rares.',
            site: 'https://www.alliance-maladies-rares.org',
            logo: allianceLogo,
        },
        {
            nom: 'Cap 92',
            description: 'Cap 92 propose un accompagnement aux personnes en situation de handicap dans le département des Hauts-de-Seine.',
            site: 'https://cap-92.fr/',
            logo: cap,
        },
        {
            nom: 'Évolia 93',
            description: 'Évolia 93 est une association qui accompagne les personnes âgées et en perte d\'autonomie en Seine-Saint-Denis.',
            site: 'https://www.evolia93.fr/',
            logo: evolia,
        },
        {
            nom: 'Fédération Française des DYS',
            description: 'Cette fédération accompagne les familles d\'enfants ayant des troubles spécifiques du langage et des apprentissages.',
            site: 'https://www.federationfrancaisedys.org',
            logo: DYSlogo,
        },
        {
            nom: 'France AVC',
            description: 'France AVC soutient les victimes d\'accidents vasculaires cérébraux et leurs familles dans leur réhabilitation.',
            site: 'https://www.franceavc.org',
            logo: AVClogo,
        },
        {
            nom: 'Association des Paralysés de France (APF)',
            description: 'L’APF milite pour les droits des personnes en situation de handicap et propose des services d\'accompagnement.',
            site: 'https://www.apf-francehandicap.org',
            logo: Apf,
        },
        {
            nom: 'UNAFAM',
            description: 'Union Nationale de Familles et Amis de Malades Mentaux, UNAFAM accompagne les familles d\'adultes atteints de troubles psychiques.',
            site: 'https://www.unafam.org',
            logo: unafam,
        },
        {
            nom: 'SOS Amitié',
            description: 'SOS Amitié propose un service d\'écoute pour toute personne en détresse, en offrant une écoute sans jugement.',
            site: 'https://www.sos-amitie.com',
            logo: sosAmitie,
        },
        {
            nom: 'Secours Populaire Français',
            description: 'Le Secours Populaire agit pour lutter contre la pauvreté et l\'exclusion sociale, en soutenant les personnes en difficulté.',
            site: 'https://www.secourspopulaire.fr',
            logo: secoursPopulaire,
        },
        {
            nom: 'HAD de Paris',
            description: 'Les hôpitaux de Paris collaborent avec PROSENIORS pour les prises en charge non médicales à domicile.',
            site: 'https://www.had-paris.fr',
            logo: hadParis,
        },
        {
            nom: 'France Mutuelle',
            description: 'Accompagne les personnes âgées et transporte les personnes à mobilité réduite.',
            site: 'https://www.francemutuelle.fr',
            logo: FranceMutuelle,
        },
        {
            nom: 'Trouvea.fr',
            description: 'Met en relation les particuliers avec des prestataires de services à la personne sur toute la France.',
            site: 'https://www.trouvea.fr',
            logo: Trouvea,
        },
        {
            nom: '3ème Vie',
            description: 'Propose des solutions d’hébergement en maison de retraite et de maintien à domicile.',
            site: 'https://www.3eme-vie.fr',
            logo: Troisvies,
        },
        {
            nom: 'Bien-être à la carte',
            description: 'Filiale du groupe Accor, elle confie des demandes d’accompagnement aux personnes âgées.',
            site: 'https://www.bienetrealacarte.fr',
            logo: BienEtre,
        },
        {
            nom: 'Europ Assistance',
            description: 'Propose des services de téléassistance pour les personnes âgées.',
            site: 'https://www.europ-assistance.fr',
            logo: EAlogo,
        },
    ];

    return (
        <>
            <Helmet>
                <title>Nos Partenaires - Hetep Iout Service</title>
                <meta name="description" content="Découvrez nos partenaires." />
                <meta name="keywords" content="partenaires, Hetep Iout Service, associations, Alliance Maladies Rares, Cap 92, Évolia 93, France AVC, APF, UNAFAM, SOS Amitié, Secours Populaire, France Mutuelle, soutien, accompagnement, handicap, personnes âgées, autonomie, téléassistance, services sociaux, aide à domicile, transport PMR"/>
            </Helmet>
            <div className={`container mx-auto px-4 py-12 ${colorMode === 'dark' ? 'transparent text-white' : 'bg-white text-gray-900'}`}>
                <h1 className="text-3xl font-extrabold text-center mb-12 text-blue-500">
                    Nos Partenaires et Associations de Soutien
                </h1>
                <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3">
                    {partenaires.map((partenaire, index) => (
                        <div
                            key={index}
                            className={`p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 transform hover:scale-105 ${colorMode === 'dark' ? 'bg-gray-700 border border-gray-600' : 'bg-white border border-gray-200'}`}
                        >
                            <div className="flex justify-center mb-4">
                                <img src={partenaire.logo} alt={`${partenaire.nom} logo`} className="h-24 w-auto object-contain" />
                            </div>
                            <h2 className={`text-2xl font-bold text-center mb-4 ${colorMode === 'dark' ? 'text-white' : 'text-gray-800'}`}>
                                {partenaire.nom} - Accompagnement et Assistance
                            </h2>
                            <p className={`text-gray-600 text-center mb-6 ${colorMode === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                                {partenaire.description}
                            </p>
                            <div className="flex justify-center">
                                <a
                                    href={partenaire.site}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 hover:text-blue-800 font-semibold transition-colors duration-300"
                                >
                                    Visiter le site de {partenaire.nom}
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default NosPartenaires;
