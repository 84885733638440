import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Confetti from 'react-confetti';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useColorMode } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');

export default function Login() {
  const { colorMode } = useColorMode();
  const [email, setEmail] = useState('');
  const [mdp, setMdp] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [lockUntil, setLockUntil] = useState(0); // Temps de verrouillage en secondes
  const [attemptsRemaining, setAttemptsRemaining] = useState(5); // Nombre de tentatives restantes
  const maxAttempts = 5; // Nombre maximum de tentatives autorisées
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/'); // Redirige vers la page d'accueil si déjà connecté
    }
  }, [navigate]);

  // Gestion du compte à rebours pour le verrouillage
  useEffect(() => {
    let timer;
    if (lockUntil > 0) {
      timer = setInterval(() => {
        setLockUntil((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [lockUntil]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://www.hisvie.com/backend/api/auth/login', { email, mdp });
      
      // Stocke le token dans le stockage local après connexion
      localStorage.setItem('token', response.data.token);
      
      setModalIsOpen(true); // Affiche le modal de bienvenue
    } catch (err) {
      if (err.response) {
        // Gestion du verrouillage et des tentatives
        if (err.response.status === 429) {
          setLockUntil(err.response.data.lockUntil || 0);
          setAttemptsRemaining(err.response.data.attemptsRemaining || maxAttempts);
          setError(err.response.data.message || 'Compte verrouillé.');
        } else if (err.response.status === 400) {
          setAttemptsRemaining(err.response.data.attemptsRemaining || maxAttempts);
          setError(err.response.data.message || 'Identifiants invalides');
        } else {
          setError('Erreur de connexion. Veuillez réessayer.');
        }
      } else {
        setError('Erreur de connexion. Veuillez vérifier votre connexion réseau.');
      }
    }
  };

  const handleGoHome = () => {
    setModalIsOpen(false);
    navigate('/'); // Redirection vers la page d'accueil
  };

  return (
    <div className={`flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 ${colorMode === 'dark' ? 'bg-gray-800' : 'bg-gray-100'}`}>
      <div className={`mt-10 sm:mx-auto sm:w-full sm:max-w-sm ${colorMode === 'dark' ? 'border border-white' : ''} rounded-lg`}>
        <div className={`p-6 rounded-lg shadow-md ${colorMode === 'dark' ? 'bg-gray-700' : 'bg-white'}`}>
          <h2 className={`text-center text-2xl font-bold leading-9 tracking-tight ${colorMode === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Se connecter à votre compte
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className={`block text-sm font-medium leading-6 ${colorMode === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                Adresse e-mail
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`block w-full rounded-md border-0 py-1.5 ${colorMode === 'dark' ? 'bg-gray-600 text-white' : 'bg-white text-gray-900'}`}
                />
              </div>
            </div>
            <div>
              <label htmlFor="mdp" className={`block text-sm font-medium leading-6 ${colorMode === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                Mot de passe
              </label>
              <div className="mt-2 relative">
                <input
                  id="mdp"
                  name="mdp"
                  type={showPassword ? 'text' : 'password'}
                  required
                  placeholder="Mot de passe"
                  value={mdp}
                  onChange={(e) => setMdp(e.target.value)}
                  className={`block w-full rounded-md border-0 py-1.5 ${colorMode === 'dark' ? 'bg-gray-600 text-white' : 'bg-white text-gray-900'}`}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-2 flex items-center text-gray-500"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
            {error && <div className="text-red-500">{error}</div>}
            {lockUntil > 0 && (
              <div className="text-red-500">
                Compte verrouillé. Temps restant : {lockUntil} secondes
              </div>
            )}
            <div className="text-gray-500">
              Tentatives restantes : {attemptsRemaining}
            </div>
            <div>
              <button
                type="submit"
                className={`flex w-full justify-center rounded-md ${colorMode === 'dark' ? 'bg-blue-700 text-white' : 'bg-blue-600 text-white'}`}
              >
                Se connecter
              </button>
            </div>
          </form>
        </div>
      </div>
      <Modal isOpen={modalIsOpen} contentLabel="Bienvenue" className="flex flex-col items-center justify-center fixed inset-0 z-50 outline-none" overlayClassName="fixed inset-0 bg-gradient-to-r from-blue-500 to-purple-600">
        <Confetti />
        <div className="bg-white rounded-lg p-10 shadow-lg w-96 text-center relative">
          <h2 className="text-3xl font-bold mb-4">Bienvenue !</h2>
          <p className="mb-4">Vous êtes connecté avec succès.</p>
          <button onClick={handleGoHome} className="mt-4 w-full justify-center rounded-md bg-green-600 py-2 text-black">
            Retour à l'accueil
          </button>
        </div>
      </Modal>
    </div>
  );
}
