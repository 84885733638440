import React, { useState } from "react";
import image10 from '../../../Asset/image10.png';
import imageMR from '../../../Asset/citation_image_patron.jpg';
import course from '../../../Asset/aide_repas.jpg';
import aide from '../../../Asset/aide_actes_essentiels.jpg';
import entretien from '../../../Asset/Entretien_Logement.jpg';
import mobilité from '../../../Asset/aide_mobilite.jpg';
import { Helmet } from 'react-helmet';
import { useColorMode } from '@chakra-ui/react';

function Senior() {
    const { colorMode } = useColorMode();

    const services = [
        {
            title: "Aide aux repas & aux courses",
            image: course,  
            details: [
                "Aide aux courses",
                "Préparation des courses",
                "Prise des repas",
                "Prise de médicaments",
                "Surveillance des régimes"
            ]
        },
        {
            title: "Aide aux actes essentiels",
            image: aide, 
            details: [
                "Aide à l’élimination",
                "Aide à la toilette",
                "Aide au lever",
                "Aide au coucher",
                "Aide pour s’habiller / se déshabiller",
                "Garde de nuit *"
            ]
        },
        {
            title: "Entretien du logement",
            image: entretien,  
            details: [
                "Entretien du linge",
                "Ménage",
                "Rangement"
            ]
        },
        {
            title: "Aide à la mobilité",
            image: mobilité,  
            details: [
                "Balades",
                "Rendez-vous médicaux",
                "Transport de PMR",
                "Garde de nuit *"
            ]
        }
    ];

    const [activeService, setActiveService] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const toggleDetails = (index) => {
        setActiveService(index === activeService ? null : index);
    };

    const toggleNightCare = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Helmet>
            <title>Séniors - Hetep Iaout Services</title>
                <link rel="canonical" href="https://www.hisvie.com/seniors/" />
                <meta property="og:locale" content="fr_FR" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Séniors - Hetep Iaout Services" />
                <meta property="og:description" content="Séniors Accueil Séniors Aide aux Personnes âgées Nous intervenons dans les cas de grande dépendance « Estampillée des agréments qualité les plus drastiques tels que Cap Handéo et Qualisap, H.I.S est reconnue sur la place parisienne comme étant le spécialiste des situations de grandes dépendances des séniors. Nous disposons d&rsquo;un personnel qualifié et formé en permanence, &hellip; Séniors Lire la suite »" />
                <meta property="og:url" content="https://www.hisvie.com/seniors/" />
                <meta property="og:site_name" content="Hetep Iaout Services" />
                <meta property="article:modified_time" content="2022-05-16T19:33:39+00:00" />
                <meta property="og:image" content="https://www.hisvie.com/wp-content/uploads/2021/07/volunteer-helping-elderly-man.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:label1" content="Durée de lecture estimée" />
                <meta name="twitter:data1" content="2 minutes" />
                <meta name="keywords" content="aide aux seniors, assistance repas, aide à la mobilité, entretien logement, actes essentiels, garde de nuit, services pour personnes âgées, dépendance, Cap Handéo, Qualisap" />
            </Helmet>

            {/* Bannière */}
            <div className="relative mb-8">
                <img
                    src={image10}
                    alt="Bannière"
                    className="w-full h-64 sm:h-72 md:h-96 object-cover"
                />
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="absolute inset-0 flex flex-col justify-center items-center text-white p-4 text-center">
                    <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold">Assistance Personnalisée pour Seniors en Grande Dépendance</h1>
                    <h2 className="text-lg sm:text-xl mt-2">Des Services Adaptés à Vos Proches</h2>
                </div>
            </div>

            {/* Contenu */}
            <div className="container mx-auto py-8 px-4 md:px-0">
                <div className="container mx-auto mb-8">
                    <div className={`flex flex-col md:flex-row items-start rounded-lg p-6 shadow-lg ${colorMode === 'dark' ? 'bg-gray-700 text-gray-300' : 'bg-white text-gray-900'}`}>
                        <img 
                            src={imageMR} 
                            alt="Aide aux personnes âgées"
                            className="w-32 h-32 sm:w-52 sm:h-52 rounded-full mr-4 mb-4 md:mb-0"
                        />
                        <div>
                            <h2 className="text-2xl sm:text-3xl font-bold mb-4">Aide aux Personnes Âgées et Dépendantes</h2>
                            <h3 className="text-lg sm:text-xl mb-2">Interventions Spécialisées pour Seniors en Dépendance</h3>
                            <div className="flex justify-center">
                                <blockquote className={`w-full md:w-[500px] h-[90px] border-l-4 border-[#00334E] pl-4 italic ${colorMode === 'dark' ? 'text-gray-300' : 'text-gray-700'} mb-2`}>
                                    « Estampillée des agréments qualité tels que Cap Handéo et Qualisap, H.I.S est reconnue sur la place parisienne comme étant le spécialiste des situations de grandes dépendances des séniors... »
                                </blockquote>
                            </div>
                            <p className="font-semibold">Christian Kenne</p>
                            <p>Directeur de l’Agence H.I.S</p>
                        </div>
                    </div>
                </div>

                {/* Services */}
                <section className="mt-12">
                    <h2 className={`text-2xl sm:text-3xl font-bold mb-6 text-center ${colorMode === 'dark' ? 'text-white' : 'text-gray-900'}`}>Nos Services d'Assistance pour Seniors</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                        {services.map((service, index) => (
                            <div key={index} className={`relative rounded-lg shadow-lg overflow-hidden ${colorMode === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
                                <div
                                    className="relative cursor-pointer transform transition-transform duration-300 hover:translate-y-[-10px] hover:shadow-lg"
                                    onClick={() => toggleDetails(index)}
                                >
                                    <img
                                        src={service.image}
                                        alt={service.title}
                                        className="w-full h-40 sm:h-48 object-cover rounded-lg"
                                    />
                                    <div className="absolute inset-0 bg-black bg-opacity-40 flex justify-center items-center text-white font-bold text-lg sm:text-xl rounded-lg">
                                        {service.title}
                                        <span className={`ml-2 transition-transform duration-300 ${activeService === index ? 'rotate-180' : ''}`}>
                                            ⯆
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className={`transition-all duration-500 ease-in-out overflow-hidden ${
                                        activeService === index ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                                    }`}
                                >
                                    <ul className={`list-disc list-inside space-y-2 ${colorMode === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'} p-4 rounded-lg shadow-lg mt-2`}>
                                        {service.details.map((detail, idx) => (
                                            <li key={idx}>{detail}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>

                {/* Section Garde de nuit */}
                <div className="mt-12">
                    <h2 className={`text-2xl sm:text-3xl font-bold mb-4 text-center ${colorMode === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                        Garde de Nuit pour Seniors<span className="text-red-500">*</span>
                    </h2>
                    <div className={` rounded-lg shadow-lg p-6 ${colorMode === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
                        <p className="text-base sm:text-lg mb-4 leading-relaxed">
                            Chez HIS, nous avons mis en place un système de garde de nuit pour assurer la sécurité et le confort de vos proches en situation de grande dépendance.
                        </p>
                        <h3 className={`text-lg sm:text-xl font-semibold mb-2 ${colorMode === 'dark' ? 'text-white' : 'text-gray-900'}`}>Accompagnement Nocturne pour Seniors</h3>
                        <p className="mb-4 leading-relaxed">
                            La garde de nuit à domicile est souvent nécessaire pour un maintien à domicile confortable et sécurisé...
                        </p>
                        <button
                            onClick={toggleNightCare}
                            className={`bg-blue-500 text-white py-2 px-4 rounded-md ${colorMode === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-600'}`}
                        >
                            {isOpen ? "Masquer les détails" : "Quelle est la différence entre nuit calme et nuit agitée ? "}
                        </button>
                        {isOpen && (
                            <div className="mt-4 p-4 rounded-lg shadow">
                                <p>
                                    Lors des nuits dites “calmes” l’aide à domicile de nuit intervient en cas de besoins (jusqu’à 3 levers dans la nuit), veillant à répondre à tous les besoins de votre proche.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Senior;
