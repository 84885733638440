import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { FaHeart } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import { useColorMode } from '@chakra-ui/react';
import SafeContent from '../Securite/safeContent'; // Assurez-vous que ce fichier existe
import DOMPurify from 'dompurify';

const BlogDetail = () => {
    const { id } = useParams();
    const { colorMode } = useColorMode();
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [liked, setLiked] = useState(false);
    const navigate = useNavigate();

    // Fonction pour extraire le texte brut du contenu HTML
    const extractPlainText = (htmlContent) => {
        const cleanHTML = DOMPurify.sanitize(htmlContent);
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = cleanHTML;
        return tempDiv.textContent || tempDiv.innerText || '';
    };

    useEffect(() => {
        const fetchBlogDetail = async () => {
            try {
                const response = await axios.get(`https://www.hisvie.com/backend/api/blog/${id}`);
                if (response.status === 200) {
                    setBlog(response.data);
                } else {
                    console.error('Erreur lors de la récupération du blog:', response.data.message);
                }
            } catch (error) {
                console.error('Erreur de réseau:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogDetail();
    }, [id]);

    const handleLikeToggle = async () => {
        try {
            let response;
            if (liked) {
                response = await axios.patch(`https://www.hisvie.com/backend/api/blog/${id}/unlike`);
                if (response.status === 200) {
                    setBlog((prevBlog) => ({
                        ...prevBlog,
                        likes: prevBlog.likes > 0 ? prevBlog.likes - 1 : 0,
                    }));
                }
            } else {
                response = await axios.patch(`https://www.hisvie.com/backend/api/blog/${id}/like`);
                if (response.status === 200) {
                    setBlog((prevBlog) => ({
                        ...prevBlog,
                        likes: prevBlog.likes + 1,
                    }));
                }
            }
            setLiked(!liked);
        } catch (error) {
            console.error('Erreur lors de la gestion du like:', error);
        }
    };

    if (loading) {
        return <p className="text-center">Chargement du blog...</p>;
    }

    if (!blog) {
        return <p className="text-center text-red-500">Erreur : Blog non trouvé ou problème de réseau.</p>;
    }

    const pageTitle = extractPlainText(blog.title);

    return (
        <>
            <Helmet>
                <title>{pageTitle || 'Blog'} - Hetep Iout Service</title>
                <meta name="description" content={extractPlainText(blog.description) || 'Lisez nos blogs dans leur intégralité.'} />
            </Helmet>
            <div
                className={`flex flex-col items-center justify-center min-h-screen p-6 ${
                    colorMode === 'dark' ? 'bg-gray-800' : 'bg-gray-100'
                }`}
            >
                <button
                    className={`mt-4 px-4 py-2 ${
                        colorMode === 'dark' ? 'bg-gray-700 text-white' : 'bg-blue-500 text-white'
                    } rounded-md hover:bg-blue-600 transition duration-300`}
                    onClick={() => navigate(-1)}
                >
                    Retour
                </button>
                <div
                    className={`shadow-lg rounded-lg p-8 w-full max-w-4xl mx-auto mt-10 ${
                        colorMode === 'dark' ? 'bg-gray-700 text-gray-300' : 'bg-white text-gray-900'
                    }`}
                >
                    <h1 className="text-3xl font-bold text-center mb-4">
                        <SafeContent htmlContent={blog.title} />
                    </h1>
                    {blog.image && (
                        <img
                            src={`https://www.hisvie.com/backend/${blog.image}`}
                            alt="Blog"
                            className="w-full h-auto rounded-md mb-4"
                        />
                    )}
                    <p className="text-lg text-center mb-2">
                        <strong>Auteur:</strong> {blog.authorId || 'Hisvie'}
                    </p>
                    <div className="text-2xl text-center mb-4">
                        <strong>Description:</strong>
                        <SafeContent htmlContent={blog.description} />
                    </div>
                    <div className="text-lg mb-4 prose lg:prose-lg dark:prose-invert">
                        <strong>Contenu:</strong>
                        <SafeContent htmlContent={blog.content} />
                    </div>
                    <div className="flex justify-end mb-4">
                        <button
                            onClick={handleLikeToggle}
                            className={`flex items-center transition duration-300 ${
                                liked ? 'text-red-500' : 'text-gray-500 hover:text-red-500'
                            }`}
                        >
                            <FaHeart className="w-8 h-8" />
                            <span className="ml-2 text-lg">{blog.likes || 0}</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogDetail;
