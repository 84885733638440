import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';

const AjouterArticle = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleFileChange = (e) => {
        setImage(e.target.files[0]);
    };

    const callTinyMCEApi = async (editorContent) => {
        try {
            const token = localStorage.getItem('token'); // Récupère le token
            await axios.post(
                'https://www.hisvie.com/backend/api/tinymce',
                { content: editorContent },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        } catch (error) {
            console.error('Erreur lors de l\'appel à l\'API TinyMCE :', error);
        }
    };

    const handleEditorChange = (setter) => async (content) => {
        setter(content); // Met à jour l'état local
        await callTinyMCEApi(content); // Appelle l'API TinyMCE
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!title || !description || !content || !image) {
            setError('Tous les champs sont requis.');
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('content', content);
        formData.append('image', image);

        setLoading(true);
        setError('');

        try {
            const token = localStorage.getItem('token'); // Token pour l'authentification
            await axios.post('https://www.hisvie.com/backend/api/blog', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });
            navigate('/blog');
        } catch (error) {
            console.error('Erreur lors de l\'ajout de l\'article :', error);
            setError('Une erreur est survenue. Veuillez réessayer.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-6">
            <div className="bg-white p-6 rounded-md shadow-md w-full max-w-3xl">
                <h1 className="text-2xl font-bold mb-6">Créer un Nouvel Article</h1>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <form onSubmit={handleSubmit}>
                    {/* Titre */}
                    <div className="mb-4">
                        <label htmlFor="title" className="block text-sm font-medium mb-2">Titre</label>
                        <Editor
                            value={title}
                            onEditorChange={handleEditorChange(setTitle)} // Appelle l'API et met à jour l'état
                            init={{
                                height: 150,
                                menubar: false,
                                plugins: 'lists link',
                                toolbar: 'bold italic underline | bullist numlist | link',
                            }}
                        />
                    </div>

                    {/* Description */}
                    <div className="mb-4">
                        <label htmlFor="description" className="block text-sm font-medium mb-2">Description</label>
                        <Editor
                            value={description}
                            onEditorChange={handleEditorChange(setDescription)} // Appelle l'API et met à jour l'état
                            init={{
                                height: 150,
                                menubar: false,
                                plugins: 'lists link',
                                toolbar: 'bold italic underline | bullist numlist | link',
                            }}
                        />
                    </div>

                    {/* Contenu */}
                    <div className="mb-4">
                        <label htmlFor="content" className="block text-sm font-medium mb-2">Contenu</label>
                        <Editor
                            value={content}
                            onEditorChange={handleEditorChange(setContent)} // Appelle l'API et met à jour l'état
                            init={{
                                height: 300,
                                menubar: true,
                                plugins: 'lists link image table code',
                                toolbar: 'undo redo | formatselect | bold italic underline | bullist numlist | link image | table code',
                            }}
                        />
                    </div>

                    {/* Image */}
                    <div className="mb-4">
                        <label htmlFor="image" className="block text-sm font-medium mb-2">Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            className="block w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>

                    {/* Bouton Soumettre */}
                    <button
                        type="submit"
                        disabled={loading}
                        className={`w-full p-3 text-white font-semibold rounded-md ${
                            loading ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
                        }`}
                    >
                        {loading ? 'Chargement...' : 'Ajouter Article'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AjouterArticle;
