import React from 'react';
import { FaFilter, FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';
import { useColorMode } from '@chakra-ui/react';

const BlogFilter = ({ filter, onFilterChange, selectedDate, onDateChange, sortByLikes, onSortChange }) => {
    const { colorMode } = useColorMode(); // Récupération du mode couleur

    return (
        <div className={`flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6 ${colorMode === 'dark' ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl`}>
            <div className="flex items-center mb-4 sm:mb-0">
                <FaFilter className="text-gray-500 mr-2" />
                <input
                    type="text"
                    placeholder="Filtrer par titre ou description"
                    value={filter}
                    onChange={onFilterChange}
                    aria-label="Filtrer par titre ou description"
                    className={`border ${colorMode === 'dark' ? 'border-gray-600' : 'border-gray-300'} p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 ${colorMode === 'dark' ? 'bg-gray-700 text-white' : 'bg-white text-gray-900'}`}
                />
            </div>
            <div className="flex items-center mb-4 sm:mb-0">
                <FaSortAmountDown className="text-gray-500 mr-2" />
                <select 
                    value={selectedDate} 
                    onChange={onDateChange} 
                    aria-label="Filtrer par date"
                    className={`border ${colorMode === 'dark' ? 'border-gray-600' : 'border-gray-300'} p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 ${colorMode === 'dark' ? 'bg-gray-700 text-white' : 'bg-white text-gray-900'}`}
                >
                    <option value="">Filtrer par date</option>
                    <option value="last24">Dernières 24h</option>
                    <option value="last3days">Derniers 3 jours</option>
                    <option value="lastWeek">Dernière semaine</option>
                    <option value="lastMonth">Dernier mois</option>
                </select>
            </div>
            <div className="flex items-center mb-4 sm:mb-0">
                <FaSortAmountUp className="text-gray-500 mr-2" />
                <select
                    onChange={onSortChange}
                    aria-label="Trier par"
                    className={`border ${colorMode === 'dark' ? 'border-gray-600' : 'border-gray-300'} p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 ${colorMode === 'dark' ? 'bg-gray-700 text-white' : 'bg-white text-gray-900'}`}
                >
                    <option value="">Trier par</option>
                    <option value="likes">Blogs les plus likés</option>
                    <option value="recent">Plus récent</option>
                    <option value="older">Plus éloigné</option>
                </select>
            </div>
        </div>
    );
};

export default BlogFilter;
