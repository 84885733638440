import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiMenu, FiX, FiChevronDown, FiArrowLeft, FiSun, FiMoon } from 'react-icons/fi';
import Logo from '../../Asset/logo.png';
import { useColorMode, Box, Button, Flex } from '@chakra-ui/react';

const Header = () => {
  const [openMenu, setOpenMenu] = useState(null);
  const [burgerOpen, setBurgerOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const { colorMode, toggleColorMode } = useColorMode();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const isLoggedIn = !!localStorage.getItem('token');

  const toggleMenu = (menu) => {
    setOpenMenu((prevMenu) => (prevMenu === menu ? null : menu));
  };

  const closeMenu = () => {
    setOpenMenu(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <Box bg={colorMode === 'dark' ? 'gray.900' : 'white'} color={colorMode === 'dark' ? 'gray.100' : 'black'}>
      <div className="relative">
        {/* Header principal */}
        <div
          className={`flex items-center justify-between shadow-lg p-4 border-b border-white ${
            colorMode === 'dark' ? 'bg-gray-900' : 'bg-gradient-to-l from-[#274559] to-[#4F8BC0]'
          }`}
        >
          {/* Logo */}
          <div className="flex items-center w-1/2 sm:w-1/4 md:w-1/6 lg:w-1/5">
            <Link to="/" onClick={closeMenu}>
                <img
                    src={Logo}
                    alt="Logo"
                    className="max-w-xs h-auto transition duration-300 ease-in-out hover:scale-105"
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxWidth: '80px',
                        filter: colorMode === 'dark'
                            ? 'drop-shadow(0 0 10px rgba(255, 255, 255, 0.8))'
                            : 'none', // Applique le filtre uniquement en mode sombre
                    }}
                />
            </Link>
        </div>



          {/* Menu burger pour mobile */}
          <div className="md:hidden pr-4">
            <button onClick={() => setBurgerOpen(!burgerOpen)}>
              {burgerOpen ? <FiX className="text-white h-8 w-8" /> : <FiMenu className="text-white h-8 w-8" />}
            </button>
          </div>

          {/* Liens de navigation (pour écran large uniquement) */}
          <ul className="hidden md:flex justify-end w-full space-x-8 text-white pr-10 items-center">
            <Link to="/agences" className="hover:text-yellow-300 transition-colors duration-300">
              <li onClick={closeMenu}>Agence</li>
            </Link>

            <li className="relative">
              <button
                onClick={() => toggleMenu('prestations')}
                className="hover:text-yellow-300 transition-colors duration-300 flex items-center"
              >
                Prestation <FiChevronDown className="ml-2" />
              </button>
              {openMenu === 'prestations' && (
                <ul className="absolute bg-white shadow-lg mt-2 p-4 space-y-4 z-20 rounded-md w-64">
                  <li>
                    <Link
                      to="/enfants"
                      className="block text-black hover:bg-gray-100 transition-colors duration-300 p-3 rounded-md"
                      onClick={closeMenu}
                    >
                      Garde d'enfants
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/seniors"
                      className="block text-black hover:bg-gray-100 transition-colors duration-300 p-3 rounded-md"
                      onClick={closeMenu}
                    >
                      Aide aux personnes âgées
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/personnes-handicapees"
                      className="block text-black hover:bg-gray-100 transition-colors duration-300 p-3 rounded-md"
                      onClick={closeMenu}
                    >
                      Accompagnement du handicap
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/transport-de-pmr"
                      className="block text-black hover:bg-gray-100 transition-colors duration-300 p-3 rounded-md"
                      onClick={closeMenu}
                    >
                      Transport de personne à mobilité réduite
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/entretien-du-domicile"
                      className="block text-black hover:bg-gray-100 transition-colors duration-300 p-3 rounded-md"
                      onClick={closeMenu}
                    >
                      Entretien du domicile
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li className="relative">
              <button
                onClick={() => toggleMenu('tarif')}
                className="hover:text-yellow-300 transition-colors duration-300 flex items-center"
              >
                Tarif <FiChevronDown className="ml-2" />
              </button>
              {openMenu === 'tarif' && (
                <ul className="absolute bg-white shadow-lg mt-2 p-4 space-y-4 z-20 rounded-md w-64">
                  <li>
                    <Link
                      to="/demande-de-devis"
                      className="block text-black hover:bg-gray-100 transition-colors duration-300 p-3 rounded-md"
                      onClick={closeMenu}
                    >
                      Demande de devis
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/tarifs"
                      className="block text-black hover:bg-gray-100 transition-colors duration-300 p-3 rounded-md"
                      onClick={closeMenu}
                    >
                      Base tarifaire
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/aide-financiere"
                      className="block text-black hover:bg-gray-100 transition-colors duration-300 p-3 rounded-md"
                      onClick={closeMenu}
                    >
                      Aide financière
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <Link to="/agrements" className="hover:text-yellow-300 transition-colors duration-300">
              <li onClick={closeMenu}>Certif</li>
            </Link>

            <Link to="/blog" className="hover:text-yellow-300 transition-colors duration-300">
              <li onClick={closeMenu}>Blog</li>
            </Link>

            <Link to="/contact" className="hover:text-yellow-300 transition-colors duration-300">
              <li onClick={closeMenu}>Contact</li>
            </Link>

            {isLoggedIn ? (
              <li className="hover:text-yellow-300 transition-colors duration-300 cursor-pointer" onClick={handleLogout}>
                Déconnexion
              </li>
            ) : (
              <Link to="/connexion" className="hover:text-yellow-300 transition-colors duration-300">
                <li onClick={closeMenu}>Connexion</li>
              </Link>
            )}
          </ul>

          {/* Toggle pour le Dark Mode */}
          <Flex justifyContent="space-between" alignItems="center" p="4">
            <Button onClick={toggleColorMode} variant="outline" colorScheme={colorMode === 'light' ? 'blackAlpha' : 'whiteAlpha'}>
              {colorMode === 'light' ? <FiMoon /> : <FiSun />} {colorMode === 'light' ? 'Mode Sombre' : 'Mode Clair'}
            </Button>
          </Flex>
        </div>

        {/* Menu slider pour mobile */}
        <div
          className={`fixed top-0 right-0 h-full w-3/4 transform ${
            burgerOpen ? 'translate-x-0' : 'translate-x-full'
          } z-50 transition-transform duration-300 ${
            colorMode === 'dark' ? 'bg-gray-900 text-gray-100' : 'bg-gradient-to-l from-[#274559] to-[#4F8BC0] text-white'
          }`}
        >       
          <div className="flex justify-between items-center p-4">
            <button
              onClick={() => setBurgerOpen(false)}
              className={`text-white hover:text-gray-300 transition-colors duration-300`}
            >
              <FiArrowLeft className="h-8 w-8" />
            </button>
            <FiX
              className="text-white hover:text-gray-300 h-8 w-8 cursor-pointer transition-colors duration-300"
              onClick={() => setBurgerOpen(false)}
            />
          </div>
          <ul className="flex flex-col space-y-8 p-10">
            <Link to="/agences" onClick={() => setBurgerOpen(false)}>
              <li className="hover:text-yellow-300 transition-colors duration-300">Agence</li>
            </Link>

            <li className="relative">
              <button
                onClick={() => toggleMenu('prestations')}
                className="hover:text-yellow-300 transition-colors duration-300 flex items-center"
              >
                Prestation <FiChevronDown className="ml-2" />
              </button>
              {openMenu === 'prestations' && (
                <ul className="mt-2 space-y-4 pl-4">
                  <li>
                    <Link
                      to="/enfants"
                      className="hover:text-yellow-300 transition-colors duration-300"
                      onClick={() => setBurgerOpen(false)}
                    >
                      Garde d'enfants
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/seniors"
                      className="hover:text-yellow-300 transition-colors duration-300"
                      onClick={() => setBurgerOpen(false)}
                    >
                      Aide aux personnes âgées
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/personnes-handicapees"
                      className="hover:text-yellow-300 transition-colors duration-300"
                      onClick={() => setBurgerOpen(false)}
                    >
                      Accompagnement du handicap
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/transport-de-pmr"
                      className="hover:text-yellow-300 transition-colors duration-300"
                      onClick={() => setBurgerOpen(false)}
                    >
                      Transport de personne à mobilité réduite
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/entretien-du-domicile"
                      className="hover:text-yellow-300 transition-colors duration-300"
                      onClick={() => setBurgerOpen(false)}
                    >
                      Entretien du domicile
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li className="relative">
              <button
                onClick={() => toggleMenu('tarif')}
                className="hover:text-yellow-300 transition-colors duration-300 flex items-center"
              >
                Tarif <FiChevronDown className="ml-2" />
              </button>
              {openMenu === 'tarif' && (
                <ul className="mt-2 space-y-4 pl-4">
                  <li>
                    <Link
                      to="/demande-de-devis" 
                      className="hover:text-yellow-300 transition-colors duration-300"
                      onClick={() => setBurgerOpen(false)}
                    >
                      Demande de devis
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/tarifs"
                      className="hover:text-yellow-300 transition-colors duration-300"
                      onClick={() => setBurgerOpen(false)}
                    >
                      Base tarifaire
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/aide-financiere"
                      className="hover:text-yellow-300 transition-colors duration-300"
                      onClick={() => setBurgerOpen(false)}
                    >
                      Aide financière
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <Link to="/agrements" onClick={() => setBurgerOpen(false)}>
              <li className="hover:text-yellow-300 transition-colors duration-300">Certif</li>
            </Link>

            <Link to="/blog" onClick={() => setBurgerOpen(false)}>
              <li className="hover:text-yellow-300 transition-colors duration-300">Blog</li>
            </Link>

            <Link to="/contact" onClick={() => setBurgerOpen(false)}>
              <li className="hover:text-yellow-300 transition-colors duration-300">Contact</li>
            </Link>

            {isLoggedIn ? (
              <li className="hover:text-yellow-300 transition-colors duration-300 cursor-pointer" onClick={handleLogout}>
                Déconnexion
              </li>
            ) : (
              <Link to="/connexion" onClick={() => setBurgerOpen(false)}>
                <li className="hover:text-yellow-300 transition-colors duration-300">Connexion</li>
              </Link>
            )}
          </ul>
        </div>
      </div>
    </Box>
  );
};

export default Header;
