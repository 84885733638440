import React from "react";
import { FaBed, FaBath, FaChild, FaBroom } from "react-icons/fa";
import { MdCheckCircle } from "react-icons/md";
import Image8 from '../../../Asset/image8.png';
import { Helmet } from 'react-helmet';
import { useColorMode } from '@chakra-ui/react';

const BaseTarifaire = () => {
    const { colorMode } = useColorMode(); 

    return (
        <div className={`${colorMode === 'dark' ? 'bg-gray-800 text-gray-100' : 'bg-white text-gray-900'}`}>
            <Helmet>
                <title>Base tarifaire - Hetep Iaout Services</title>
                <link rel="canonical" href="https://www.hisvie.com/tarifs/" />
                <meta property="og:title" content="Base tarifaire - Hetep Iaout Services" />
                <meta name="keywords" content="tarifs aide à domicile, garde de nuit, aide aux personnes âgées, garde d'enfants" />
            </Helmet>

            {/* Bannière */}
            <div className="relative mb-6 w-full h-60 md:h-96"> 
                <img src={Image8} alt="Bannière" className="w-full h-full object-cover" />
                <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-60">
                    <h1 className="text-3xl md:text-5xl font-bold text-white text-center mb-2">
                        Tarifs de nos prestations d'aide à domicile
                    </h1>
                    <p className="text-base md:text-lg text-white text-center max-w-xl">
                        Après étude de votre situation et de votre degré de dépendance, un devis gratuit vous est proposé.
                    </p>
                </div>
            </div>

            {/* Cartes de services */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 max-w-6xl mx-auto">
                {[
                    {
                        icon: <FaBed className="text-blue-600 text-6xl mb-3" />,
                        title: "Garde/Nuit",
                        price: "27.31€ / A partir de",
                        description: "De jour comme de nuit, nos équipes demeurent à votre disposition.",
                        items: ["Aide aux coucher", "Assistance nocturne"]
                    },
                    {
                        icon: <FaBath className="text-blue-500 text-6xl mb-3" />,
                        title: "Actes Essentiels",
                        price: "27.31€ / A partir de",
                        description: "Solutions adaptées pour les personnes en perte d’autonomie.",
                        items: ["Aide à la toilette", "Aide à l’élimination", "Aide au lever", "Aide au coucher", "Aide pour s’habiller", "Aide pour se déshabiller"]
                    },
                    {
                        icon: <FaChild className="text-blue-500 text-6xl mb-3" />,
                        title: "Garde d'Enfants",
                        price: "27.31€ / A partir de",
                        description: "Faites garder vos enfants par des professionnels à domicile.",
                        items: ["Sorties d'écoles", "Sorties de crèche", "Complément de mode de garde", "Babysitting", "Enfants en situation de handicap"]
                    },
                    {
                        icon: <FaBroom className="text-blue-500 text-6xl mb-3" />,
                        title: "Ménage",
                        price: "27.31€ / A partir de",
                        description: "Confiez-nous l’entretien de votre domicile.",
                        items: ["Ménage régulier", "Ménage lourd", "Ménage occasionnel"]
                    }
                ].map((card, index) => (
                    <div key={index} className={`border rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 p-6 ${colorMode === 'dark' ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-200'}`}>
                        <div className="flex flex-col items-center">
                            {card.icon}
                            <h2 className="text-xl font-semibold mb-1">{card.title}</h2>
                            <p className="font-bold text-2xl">{card.price}</p>
                            <p className="text-center mt-4">{card.description}</p>
                            <hr className="my-4 border-gray-300 w-full" />
                            <ul className="list-none mt-4 space-y-2">
                                {card.items.map((item, idx) => (
                                    <li key={idx} className="flex items-center">
                                        <MdCheckCircle className="text-blue-500 mr-2" />
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>

            {/* Section Contact */}
            <div className="flex justify-center mt-10 mb-10">
                <div className={`border rounded-lg shadow-md p-6 max-w-md text-center ${colorMode === 'dark' ? 'bg-gray-700 border-gray-600 text-gray-100' : 'bg-gray-100 border-gray-300 text-gray-900'}`}>
                    <h2 className="text-2xl font-bold mb-4">Pour plus de précisions</h2>
                    <p className="mb-6">Contactez-nous pour toute question ou demande d'information supplémentaire.</p>
                    <a href="/contact" className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-300">
                        Contactez-nous
                    </a>
                </div>
            </div>
        </div>
    );
};

export default BaseTarifaire;
