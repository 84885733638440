import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaTiktok, FaLinkedin } from 'react-icons/fa';
import Logo from '../../Asset/logo.png';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useColorMode } from '@chakra-ui/react';

function Footer() {
    const [email, setEmail] = useState('');
    const { colorMode } = useColorMode();

    const handleNewsletterSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/email/send-email', {
                senderEmail: 'newsletter@yourdomain.com', // L'expéditeur (ex. : une adresse générique pour votre newsletter)
                receiverEmail: 'his@donnerlavieauxannees.com', // Adresse de réception
                subject: 'Nouvelle inscription à la newsletter',
                htmlContent: `<p>Nouvel utilisateur inscrit : <strong>${email}</strong></p>`,
            });

            if (response.status === 201) {
                toast.success('Merci pour votre inscription à la newsletter !');
                setEmail(''); // Réinitialisation du champ email
            }
        } catch (error) {
            console.error('Erreur lors de l\'inscription à la newsletter :', error);
            toast.error('Une erreur est survenue. Veuillez réessayer.');
        }
    };
    
    
    return (
        <footer className={`bg-gradient-to-b ${colorMode === 'dark' ? 'from-gray-800 to-gray-900' : 'from-[#4f8bc0] to-[#274559]'} font-sans`}>
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar />
            <div className="container px-4 py-12 mx-auto">
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
                    {/* Section Newsletter */}
                    <div className="sm:col-span-2">
                        <h1 className={`max-w-lg text-xl font-semibold tracking-tight ${colorMode === 'dark' ? 'text-white' : 'text-gray-800'} xl:text-2xl`}>
                            Abonnez-vous à notre newsletter pour obtenir des mises à jour.
                        </h1>

                        <form onSubmit={handleNewsletterSubmit} className="flex flex-col mx-auto mt-6 space-y-3 md:space-y-0 md:flex-row">
                            <input
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={`px-4 py-2 ${colorMode === 'dark' ? 'text-white bg-gray-900 border-gray-600' : 'text-gray-700 bg-white border-gray-300'} border rounded-md focus:outline-none focus:ring focus:ring-opacity-40 focus:ring-blue-300`}
                                placeholder="Adresse e-mail"
                                required
                            />
                            <button 
                                type="submit"
                                className="w-full px-6 py-2.5 text-sm font-medium tracking-wider text-white transition-colors duration-300 transform md:w-auto md:mx-4 focus:outline-none bg-gray-800 rounded-lg hover:bg-gray-700 focus:ring focus:ring-gray-300 focus:ring-opacity-80"
                            >
                                S'abonner
                            </button>
                        </form>
                    </div>

                    {/* Liens rapides */}
                    <div className="flex flex-col sm:col-span-1 lg:col-span-1">
                        <p className={`font-semibold ${colorMode === 'dark' ? 'text-white' : 'text-gray-800'}`}>Liens rapides</p>
                        <div className="flex flex-col items-start mt-1 space-y-2">
                            <Link to="/">
                                <p className={`transition-colors duration-300 ${colorMode === 'dark' ? 'text-white hover:text-blue-400' : 'text-gray-800 hover:text-red-500'} hover:underline`}>Accueil</p>
                            </Link>
                            <Link to="/demande-de-devis">
                                <p className={`transition-colors duration-300 ${colorMode === 'dark' ? 'text-white hover:text-blue-400' : 'text-gray-800 hover:text-red-500'} hover:underline`}>Devis</p>
                            </Link>
                            <Link to="/recrutement">
                                <p className={`transition-colors duration-300 ${colorMode === 'dark' ? 'text-white hover:text-blue-400' : 'text-gray-800 hover:text-red-500'} hover:underline`}>Nous recrutons</p>
                            </Link>
                            <Link to="/NosPartenaires">
                                <p className={`transition-colors duration-300 ${colorMode === 'dark' ? 'text-white hover:text-blue-400' : 'text-gray-800 hover:text-red-500'} hover:underline`}>Nos Partenaires</p>
                            </Link>
                            <Link to="/NosRessources">
                                <p className={`transition-colors duration-300 ${colorMode === 'dark' ? 'text-white hover:text-blue-400' : 'text-gray-800 hover:text-red-500'} hover:underline`}>Nos ressources</p>
                            </Link>
                        </div>
                    </div>
                    {/* Contact */}
                    <div className="flex flex-col sm:col-span-1 lg:col-span-1">
                        <p className={`font-semibold ${colorMode === 'dark' ? 'text-white' : 'text-gray-800'}`}>Contact</p>
                        <div className="flex flex-col items-start mt-1 space-y-2">
                            <p className={`transition-colors duration-300 ${colorMode === 'dark' ? 'text-white hover:text-blue-400' : 'text-gray-800 hover:text-red-500'} hover:underline`}>Téléphone: 01 43 52 64 23</p>
                            <p className={`transition-colors duration-300 ${colorMode === 'dark' ? 'text-white hover:text-blue-400' : 'text-gray-800 hover:text-red-500'} hover:underline`}>Email: his@donnerlavieauxannees.com</p>
                            <Link to='/mentions-legales'>
                                <p className={`transition-colors duration-300 ${colorMode === 'dark' ? 'text-white hover:text-blue-400' : 'text-gray-800 hover:text-red-500'} hover:underline`}>Conditions légales</p>
                            </Link>
                        </div>
                    </div>

                    {/* Logo */}
                    <div className="flex justify-center sm:col-span-2 lg:col-span-1">
                        <Link to='/Home'>
                            <img 
                                src={Logo} 
                                alt='Logo' 
                                className='h-[100px] w-auto bg-transparent transition duration-300 ease-in-out'
                                style={{ filter: colorMode === 'dark' ? 'drop-shadow(0 0 10px rgba(255, 255, 255, 0.8))' : 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.2))' }} 
                            />
                        </Link>
                    </div>
                </div>

                <hr className={`my-6 border-2 ${colorMode === 'dark' ? 'border-gray-700' : 'border-gray-200'} md:my-8`} />

                {/* Bas du footer */}
                <div className="flex flex-col items-center mt-5">
                    <div className='flex gap-4'>
                        <a href="https://www.facebook.com/his2015/?locale=fr_FR" className={`transition-colors ${colorMode === 'dark' ? 'text-white hover:text-blue-400' : 'text-blue-600 hover:text-blue-500'}`}>
                            <FaFacebook size={30} />
                        </a>
                        <a href="https://www.instagram.com/hetepiaoutservices/?igsh=MTFkZm4xYjRxYzVrNA%3D%3D&utm_source=qr" className={`transition-colors ${colorMode === 'dark' ? 'text-white hover:text-pink-400' : 'text-pink-600 hover:text-pink-500'}`}>
                            <FaInstagram size={30} />
                        </a>
                        <a href="https://www.tiktok.com/@his1562?_t=8qmefUtS6KX&_r=1" className={`transition-colors ${colorMode === 'dark' ? 'text-white hover:text-gray-500' : 'text-black hover:text-gray-700'}`}>
                            <FaTiktok size={30} />
                        </a>
                        <a href="https://www.linkedin.com/company/hetepiaoutservices/" className={`transition-colors ${colorMode === 'dark' ? 'text-white hover:text-blue-500' : 'text-blue-700 hover:text-blue-600'}`}>
                            <FaLinkedin size={30} />
                        </a>
                    </div>

                    <div className={`text-center mt-5 ${colorMode === 'dark' ? 'text-white' : 'text-black'}`}>
                        <p className='text-sm'>&copy; {new Date().getFullYear()} Votre Société. Tous droits réservés.</p>
                        <p className='text-sm'>Conditions générales d'utilisation | Politique de confidentialité</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
