import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useColorMode } from '@chakra-ui/react'; // Import useColorMode
import SafeContent from '../Securite/safeContent';

const RessourcesList = () => {
    const { colorMode } = useColorMode(); // Get the current color mode
    const [ressources, setRessources] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const baseURL = 'https://www.hisvie.com/backend';

    useEffect(() => {
        const fetchRessources = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/ressources/recuperer`);
                console.log('Données récupérées:', response.data);
                setRessources(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des ressources:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchRessources();
    }, []);

    const isLoggedIn = !!localStorage.getItem('token');

    if (loading) {
        return <div>Chargement des ressources...</div>;
    }

    const handleAddRessource = () => {
        navigate('/RessourcesForm');
    };

    const renderResourceContent = (ressource) => {
        return (
            <div className="flex items-center space-x-4">
                {ressource.imagePath && (
                    <img
                        src={`${baseURL}${ressource.imagePath}`}
                        alt={`Illustration de ${ressource.title}`}
                        className="w-20 h-20 object-cover border border-gray-300 rounded-lg"
                        aria-label={`Image de ${ressource.title}`}
                    />
                )}

                <div className="flex-1">
                    <SafeContent htmlContent={ressource.title} />

                    {ressource.fileType.includes('pdf') ? (
                        <div className="flex items-center space-x-2">
                            <a
                                href={`${baseURL}${ressource.filePath}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`text-sm px-2 py-1 rounded-md transition ${colorMode === 'dark' ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
                                aria-label={`Ouvrir le PDF: ${ressource.title}`}
                            >
                                Ouvrir le fichier PDF
                            </a>
                            <a
                                href={`${baseURL}${ressource.filePath}`}
                                download
                                className={`text-xs px-2 py-1 rounded-md transition ${colorMode === 'dark' ? 'bg-gray-800 text-white hover:bg-gray-700' : 'bg-gray-300 text-gray-800 hover:bg-gray-400'}`}
                                aria-label={`Télécharger le PDF: ${ressource.title}`}
                            >
                                Télécharger
                            </a>
                        </div>
                    ) : ressource.fileType.includes('audio') ? (
                        <audio controls className="w-full" aria-label={`Audio: ${ressource.title}`}>
                            <source src={`${baseURL}${ressource.filePath}`} type={ressource.fileType} />
                            Votre navigateur ne supporte pas l'élément audio.
                        </audio>
                    ) : (
                        <div className="flex items-center space-x-2">
                            <a
                                href={`${baseURL}${ressource.filePath}`}
                                className={`text-sm px-2 py-1 rounded-md transition ${colorMode === 'dark' ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
                                download
                                aria-label={`Télécharger ${ressource.title}`}
                            >
                                Télécharger
                            </a>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const groupedResources = () => {
        const audioAndVideo = ressources.filter(r => r.fileType.includes('audio') || r.fileType.includes('video'));
        const documents = ressources.filter(r => !r.fileType.includes('audio') && !r.fileType.includes('video'));

        return { audioAndVideo, documents };
    };

    const { audioAndVideo, documents } = groupedResources();

    return (
        <>
        <Helmet>
            <title>Ressources - Hetep Iaout Services</title>
            <link rel="canonical" href="https://www.hisvie.com/ressources/" />
            <meta property="og:locale" content="fr_FR" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Ressources - Hetep Iaout Services" />
            <meta property="og:description" content="Ressources utiles Dans cette rubrique, vous pourrez télécharger un certain nombre de documents utiles.&nbsp; Livret d&rsquo;accueil Hetep Iaout Services Charte de déontologie Charte des droits et libertés des personnes âgées et handicapées Charte bénéficiaire Cahier de liaison Synthèse de l&rsquo;enquête de satisfaction 2022" />
            <meta property="og:url" content="https://www.hisvie.com/ressources/" />
            <meta property="og:site_name" content="Hetep Iaout Services" />
            <meta property="article:modified_time" content="2023-05-25T14:04:15+00:00" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:label1" content="Durée de lecture estimée" />
            <meta name="twitter:data1" content="1 minute" />
            <meta name="keywords" content="Ressources, Services à la personne, Aide à domicile, Accompagnement, Ressources PDF, Ressources audio, Téléchargement de fichiers, Documents de support, Assistance pour seniors, Éducation, Informations pratiques, Santé et bien-être, Ressources pour familles, Accès aux ressources, Gestion des ressources, Agence certifiée, Informations pour handicapés, Outils pour aidants"/>
        </Helmet>
        <div className={`p-8 ${colorMode === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
            <h1 className="text-3xl font-extrabold mb-10">Liste des Ressources</h1>
            
            {isLoggedIn && (
                <button
                    onClick={handleAddRessource}
                    className={`mb-8 px-6 py-3 ${colorMode === 'dark' ? 'bg-blue-600' : 'bg-blue-600'} text-white text-lg rounded-lg hover:bg-blue-500 transition duration-300`}
                    aria-label="Ajouter une nouvelle ressource"
                >
                    Ajouter Ressource
                </button>
            )}

            <h2 className="text-2xl font-bold mb-4">Audios et Vidéos</h2>
            <ul className="space-y-8">
                {audioAndVideo.map((ressource) => (
                    <li key={ressource.title} className={`p-4 shadow-md rounded-lg border ${colorMode === 'dark' ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-200'}`}>
                        <h2 className={`text-xl font-bold mb-2 ${colorMode === 'dark' ? 'text-white' : 'text-gray-800'}`}>{ressource.title}</h2>
                        {renderResourceContent(ressource)}
                    </li>
                ))}
            </ul>

            <h2 className="text-2xl font-bold mb-4 mt-10">Documents</h2>
            <ul className="space-y-8">
                {documents.map((ressource) => (
                    <li key={ressource.title} className={`p-4 shadow-md rounded-lg border ${colorMode === 'dark' ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-200'}`}>
                        {renderResourceContent(ressource)}
                    </li>
                ))}
            </ul>
        </div>
        </>
    );
};

export default RessourcesList;