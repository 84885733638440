import React, { useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import { useColorMode } from '@chakra-ui/react';
import "react-toastify/dist/ReactToastify.css";

function Contact() {
    const { colorMode } = useColorMode(); // Récupérer le mode couleur actuel
    const isDark = colorMode === 'dark'; // Vérifier si le mode est sombre

    const [formData, setFormData] = useState({
        nom: "",
        prenom: "",
        email: "",
        telephone: "",
        message: "",
    });

    const [checkboxes, setCheckboxes] = useState({
        gardeEnfant: false,
        aideHandicapes: false,
        aideSeniors: false,
        transportPMR: false,
        entretienDomicile: false,
        aidesFinancieres: false,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCheckboxes({ ...checkboxes, [name]: checked });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.post('/api/email/send-email', {
                senderEmail: 'contact@his.com', // Email du sender
                receiverEmail: 'his@donnerlavieauxannees.com', // Email du receiver
                subject: 'Nouveau formulaire de contact',
                htmlContent: `
                    <h1>Informations du formulaire</h1>
                    <p><strong>Nom:</strong> ${formData.nom}</p>
                    <p><strong>Prénom:</strong> ${formData.prenom}</p>
                    <p><strong>Email:</strong> ${formData.email}</p>
                    <p><strong>Téléphone:</strong> ${formData.telephone}</p>
                    <p><strong>Message:</strong> ${formData.message}</p>
                    <p><strong>Intérêts:</strong></p>
                    <ul>
                        ${checkboxes.gardeEnfant ? "<li>Garde d'enfant</li>" : ""}
                        ${checkboxes.aideHandicapes ? "<li>Aide aux personnes handicapées</li>" : ""}
                        ${checkboxes.aideSeniors ? "<li>Aide aux Séniors</li>" : ""}
                        ${checkboxes.transportPMR ? "<li>Transport PMR</li>" : ""}
                        ${checkboxes.entretienDomicile ? "<li>Entretien de domicile</li>" : ""}
                        ${checkboxes.aidesFinancieres ? "<li>Aides financières</li>" : ""}
                    </ul>
                `,
            });
    
            if (response.status === 201) {
                toast.success("Le formulaire a été envoyé avec succès !");
                // Réinitialisez le formulaire
                setFormData({
                    nom: "",
                    prenom: "",
                    email: "",
                    telephone: "",
                    message: "",
                });
                setCheckboxes({
                    gardeEnfant: false,
                    aideHandicapes: false,
                    aideSeniors: false,
                    transportPMR: false,
                    entretienDomicile: false,
                    aidesFinancieres: false,
                });
            }
        } catch (error) {
            console.error("Erreur lors de l'envoi du formulaire :", error);
            toast.error("Une erreur est survenue lors de l'envoi du formulaire.");
        }
    };
    
    const ldJson = {
        "@context": "https://schema.org",
        "@type": "ContactPage",
        "url": "https://www.hisvie.com/contact",
        "name": "Hisvie Hetep Iout Service", 
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+33 1 23 45 67 89",
          "contactType": "Customer Service",
          "areaServed": "FR",
          "availableLanguage": ["French"]
        },
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "8 Place Georges Braques",
          "addressLocality": "93120 ",
          "postalCode": "La Courneuve",
          "addressCountry": "France"
        }
      };

    return (
        <>
        <Helmet>
        <title>Contact - Hetep Iaout Services</title>
            <link rel="canonical" href="https://www.hisvie.com/contact/" />
            <meta property="og:locale" content="fr_FR" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Contact - Hetep Iaout Services" />
            <meta property="og:description" content="Contact Accueil Contact Formulaire de contact Votre nom* Votre e-mail* Votre numéro de téléphone* Objet Votre message J&rsquo;accepte que mes données personnelles soient traitées* Identité PrénomNomAdresse de contactE-mailConfirmez l’e-mailTéléphone Je souhaite être contacté pour des informations concernant : Garde d&rsquo;enfant Aide aux personnes handicapées Aide aux Séniors Transport de PMR Entretien de mon &hellip; Contact Lire la suite »" />
            <meta property="og:url" content="https://www.hisvie.com/contact/" />
            <meta property="og:site_name" content="Hetep Iaout Services" />
            <meta property="article:modified_time" content="2024-09-30T14:13:00+00:00" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:label1" content="Durée de lecture estimée" />
            <meta name="twitter:data1" content="1 minute" />
            <meta name="keywords" content="contact, service à la personne, aide aux handicapés, aide aux seniors, entretien domicile, assistance, La Courneuve, informations, rendez-vous, HIS, Hetep Iaout Services"/>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(ldJson),
                }}
            />
        </Helmet>
        <ToastContainer position="top-center" autoClose={5000} hideProgressBar />
        <section className={`bg-${isDark ? 'gray-800' : 'blue-50'} text-${isDark ? 'white' : 'gray-900'}`} id="contact">
            <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
                <div className="mb-4">
                    <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
                        <h1 className="font-heading mb-4 font-bold tracking-tight text-3xl sm:text-5xl">
                            Contactez Hetep Iout Service
                        </h1>
                        <p className="mx-auto mt-4 max-w-3xl text-xl">
                            Nous sommes à votre écoute pour tous vos besoins en services à la personne !
                        </p>
                    </div>
                </div>
                <div className="flex items-stretch justify-center">
                    <div className="grid md:grid-cols-2">
                        <div className="h-full pr-6">
                            <h2 className="text-2xl font-bold mb-6">Nos Services Disponibles</h2>
                            <p className={`mt-3 mb-12 text-lg ${isDark ? 'text-gray-300' : 'text-gray-800'}`}>
                                N’hésitez pas à nous contacter pour toute demande d’information ou pour un rendez-vous. 
                                Notre équipe est là pour vous accompagner avec les services suivants : garde d'enfant, aide aux personnes handicapées, aide aux seniors, entretien de domicile, et bien plus.
                            </p>
                            <ul className="mb-6 md:mb-0">
                                <li className="flex">
                                    <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-500 text-gray-50">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                                            <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                            <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                                        </svg>
                                    </div>
                                    <div className="ml-4 mb-4">
                                        <h3 className={`mb-2 text-lg font-medium leading-6 ${isDark ? 'text-gray-300' : 'text-gray-900'}`}>Notre Siège</h3>
                                        <p className={` ${isDark ? 'text-gray-400' : 'text-gray-800'}`}>8 Place Georges Braques</p>
                                        <p className={` ${isDark ? 'text-gray-400' : 'text-gray-800'}`}>93120 La Courneuve, France</p>
                                    </div>
                                </li>
                                <li className="flex">
                                    <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-500 text-gray-50">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                                            <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                                            <path d="M15 7a2 2 0 0 1 2 2" />
                                            <path d="M15 3a6 6 0 0 1 6 6" />
                                        </svg>
                                    </div>
                                    <div className="ml-4 mb-4">
                                        <h3 className={`mb-2 text-lg font-medium leading-6 ${isDark ? 'text-gray-300' : 'text-gray-900'}`}>Contact</h3>
                                        <p className={` ${isDark ? 'text-gray-400' : 'text-gray-800'}`}>Mobile: +33 1 43 52 64 23</p>
                                        <p className={` ${isDark ? 'text-gray-400' : 'text-gray-800'}`}>Mail: his@donnerlavieauxannees.com</p>
                                    </div>
                                </li>
                                <li className="flex">
                                    <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-500 text-gray-50">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                                            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                            <path d="M12 7v5l3 3" />
                                        </svg>
                                    </div>
                                    <div className="ml-4 mb-4">
                                        <h3 className={`mb-2 text-lg font-medium leading-6 ${isDark ? 'text-gray-300' : 'text-gray-900'}`}>Heures d'ouverture</h3>
                                        <ul className={` ${isDark ? 'text-gray-400' : 'text-gray-800'}`}>
                                            <li>Lundi - Vendredi : 9h00 - 18h00</li>
                                            <li>Samedi : 10h00 - 16h00</li>
                                            <li>Dimanche : Fermé</li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className={`rounded-lg ${isDark ? 'bg-gray-700 text-white' : 'bg-white text-gray-900'} p-8 shadow-md`}>
    <h2 className="text-2xl font-bold leading-6">Contactez-nous</h2>
    <form onSubmit={handleSubmit} className="space-y-4 mt-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
                type="text"
                name="nom"
                value={formData.nom}
                onChange={handleChange}
                placeholder="Nom"
                className={`block w-full rounded border ${isDark ? 'border-gray-500 bg-gray-600 text-white' : 'border-gray-300 bg-white text-gray-900'} px-4 py-2`}
                required
            />
            <input
                type="text"
                name="prenom"
                value={formData.prenom}
                onChange={handleChange}
                placeholder="Prénom"
                className={`block w-full rounded border ${isDark ? 'border-gray-500 bg-gray-600 text-white' : 'border-gray-300 bg-white text-gray-900'} px-4 py-2`}
                required
            />
        </div>
        <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            className={`block w-full rounded border ${isDark ? 'border-gray-500 bg-gray-600 text-white' : 'border-gray-300 bg-white text-gray-900'} px-4 py-2`}
            required
        />
        <input
            type="tel"
            name="telephone"
            value={formData.telephone}
            onChange={handleChange}
            placeholder="Téléphone"
            className={`block w-full rounded border ${isDark ? 'border-gray-500 bg-gray-600 text-white' : 'border-gray-300 bg-white text-gray-900'} px-4 py-2`}
            required
        />
        <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Votre message"
            className={`block w-full rounded border ${isDark ? 'border-gray-500 bg-gray-600 text-white' : 'border-gray-300 bg-white text-gray-900'} px-4 py-2`}
            required
        ></textarea>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <label className="flex items-center">
                <input
                    type="checkbox"
                    name="gardeEnfant"
                    checked={checkboxes.gardeEnfant}
                    onChange={handleCheckboxChange}
                    className={`h-4 w-4 rounded ${isDark ? 'border-gray-500 bg-gray-700' : 'border-gray-300 bg-white'} text-blue-600 focus:ring-blue-500`}
                />
                <span className={`${isDark ? 'text-gray-300' : 'text-gray-800'} ml-2`}>Garde d'enfant</span>
            </label>
            <label className="flex items-center">
                <input
                    type="checkbox"
                    name="aideHandicapes"
                    checked={checkboxes.aideHandicapes}
                    onChange={handleCheckboxChange}
                    className={`h-4 w-4 rounded ${isDark ? 'border-gray-500 bg-gray-700' : 'border-gray-300 bg-white'} text-blue-600 focus:ring-blue-500`}
                />
                <span className={`${isDark ? 'text-gray-300' : 'text-gray-800'} ml-2`}>Aide aux personnes handicapées</span>
            </label>
            <label className="flex items-center">
                <input
                    type="checkbox"
                    name="aideSeniors"
                    checked={checkboxes.aideSeniors}
                    onChange={handleCheckboxChange}
                    className={`h-4 w-4 rounded ${isDark ? 'border-gray-500 bg-gray-700' : 'border-gray-300 bg-white'} text-blue-600 focus:ring-blue-500`}
                />
                <span className={`${isDark ? 'text-gray-300' : 'text-gray-800'} ml-2`}>Aide aux seniors</span>
            </label>
            <label className="flex items-center">
                <input
                    type="checkbox"
                    name="entretienDomicile"
                    checked={checkboxes.entretienDomicile}
                    onChange={handleCheckboxChange}
                    className={`h-4 w-4 rounded ${isDark ? 'border-gray-500 bg-gray-700' : 'border-gray-300 bg-white'} text-blue-600 focus:ring-blue-500`}
                />
                <span className={`${isDark ? 'text-gray-300' : 'text-gray-800'} ml-2`}>Entretien domicile</span>
            </label>
            <label className="flex items-center">
                <input
                    type="checkbox"
                    name="transportPMR"
                    checked={checkboxes.transportPMR}
                    onChange={handleCheckboxChange}
                    className={`h-4 w-4 rounded ${isDark ? 'border-gray-500 bg-gray-700' : 'border-gray-300 bg-white'} text-blue-600 focus:ring-blue-500`}
                />
                <span className={`${isDark ? 'text-gray-300' : 'text-gray-800'} ml-2`}>Transport PMR</span>
            </label>
            <label className="flex items-center">
                <input
                    type="checkbox"
                    name="aidesFinancieres"
                    checked={checkboxes.aidesFinancieres}
                    onChange={handleCheckboxChange}
                    className={`h-4 w-4 rounded ${isDark ? 'border-gray-500 bg-gray-700' : 'border-gray-300 bg-white'} text-blue-600 focus:ring-blue-500`}
                />
                <span className={`${isDark ? 'text-gray-300' : 'text-gray-800'} ml-2`}>Aides financières</span>
            </label>
        </div>

        <button
            type="submit"
            className="mt-4 w-full rounded bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-700 focus:bg-blue-800"
        >
            Envoyer
        </button>
    </form>
</div>

                    </div>
                </div>
            </div>
        </section>
        <section className={`py-16 ${colorMode === 'dark' ? 'bg-gray-800' : 'bg-blue-50'}`}>
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 className={`mb-4 text-3xl font-bold leading-6 text-center ${colorMode === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Notre Localisation
        </h2>
        <div className="rounded-lg shadow-lg overflow-hidden">
            <div className={`${colorMode === 'dark' ? 'bg-blue-700' : 'bg-blue-600'} text-white text-center py-3`}>
                <h3 className="text-xl font-semibold">Visitez-nous</h3>
                <p className="text-sm">8 Place Georges Braques, 93120 La Courneuve</p>
            </div>
            <div className="h-96">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d655.3612702497396!2d2.3789303696589985!3d48.92596389820884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66c1b4b3e79fd%3A0x8d353867f687e3e6!2s8%20Pl.%20Georges%20Braque%2C%2093120%20La%20Courneuve!5e0!3m2!1sfr!2sfr!4v1728565418490!5m2!1sfr!2sfr"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Carte de notre localisation à La Courneuve"
                ></iframe>
            </div>
        </div>
    </div>
</section>

        </>
    );
}

export default Contact;
