import React, { useState } from "react";
import { FaUserFriends, FaChild, FaAccessibleIcon, FaPeopleArrows, FaHandsHelping } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useColorMode } from '@chakra-ui/react';

function Accordion({ title, content }) {
    const [isOpen, setIsOpen] = useState(false);
    const { colorMode } = useColorMode();

    return (
        <div className={`border rounded-lg mb-4 shadow-lg hover:shadow-xl transition-shadow ${colorMode === 'dark' ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-200'}`}>
            <button 
                className={`w-full text-left p-5 focus:outline-none transition-colors ${colorMode === 'dark' ? 'bg-gray-800 text-gray-200' : 'bg-gray-100 text-gray-800'}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <h3 className="flex items-center justify-between text-lg font-semibold">
                    {title}
                    <span className={`ml-auto transform ${isOpen ? 'rotate-180' : ''} transition-transform`}>▼</span>
                </h3>
            </button>
            {isOpen && (
                <div className={`p-4 ${colorMode === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-700'} border-t`}>{content}</div>
            )}
        </div>
    );
}

function Presentation() {
    const { colorMode } = useColorMode();

    return (
        <>
            <Helmet>
                <title>Présentation et Services - Hetep Iout Service</title>
                <meta name="description" content="Qui sommes-nous?" />
                <meta name="keywords" content="Hetep Iout Service, aide à domicile, services de soins, personnes handicapées, soutien quotidien, trachéotomie, dépendance, enfants en situation de handicap, soins spécialisés, accompagnement personnalisé, rééducation post-trauma, personnes âgées, autonomie, assistance à domicile, Paris" />
            </Helmet>

            <div className={`presentation p-12 ${colorMode === 'dark' ? 'bg-gray-800 text-white' : 'bg-blue-50 text-gray-900'} min-h-screen`}>
                <h1 className={`text-5xl font-extrabold text-center mb-10 ${colorMode === 'dark' ? 'text-blue-400' : 'text-blue-500'}`}>
                    Présentation de notre Agence d’Aide à Domicile et Services Spécialisés
                </h1>

                <div className={`shadow-xl rounded-lg p-10 mb-12 ${colorMode === 'dark' ? 'bg-gray-700 border-gray-600 text-gray-300' : 'bg-white border-gray-300 text-gray-700'}`}>
                    <p className="text-xl leading-relaxed mb-6 text-center">
                        Bienvenue chez HIS, où notre mission est de fournir des services spécialisés aux personnes âgées, aux personnes en situation de handicap et aux personnes atteintes de maladies rares ou orphelines.
                    </p>
                    <p className="text-xl leading-relaxed text-center">
                        Notre équipe d'intervenants qualifiés est habilitée à pratiquer des soins spécifiques tels que la gestion de la trachéotomie et d'autres techniques avancées.
                    </p>
                </div>

                <h2 className={`text-4xl font-semibold mb-8 text-center ${colorMode === 'dark' ? 'text-blue-500' : 'text-blue-600'}`}>
                    Compétences et Services de nos Intervenants
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mb-12">
                    {[{
                        icon: <FaUserFriends />, title: 'Soins médicaux', content: 'Nos intervenants sont formés pour prodiguer des soins médicaux à domicile, notamment pour les pathologies lourdes et complexes.'
                    }, {
                        icon: <FaAccessibleIcon />, title: 'Trachéotomie', content: 'Nos professionnels sont experts dans la gestion des trachéotomies et autres soins techniques nécessitant une attention particulière.'
                    }, {
                        icon: <FaHandsHelping />, title: 'Assistance quotidienne', content: 'Aide personnalisée pour les tâches quotidiennes afin de maintenir une qualité de vie optimale.'
                    }, {
                        icon: <FaPeopleArrows />, title: 'Accompagnement pour voyage et courses', content: 'Nos intervenants accompagnent les personnes lors de voyages ou d’activités quotidiennes, pour assurer leur sécurité et bien-être.'
                    }, {
                        icon: <FaAccessibleIcon />, title: 'Spécialistes en maladies rares', content: 'Interventions spécialisées pour la prise en charge des maladies rares et orphelines, avec un accompagnement adapté.'
                    }, {
                        icon: <FaChild />, title: 'Gardes d’enfants en situation de handicap', content: 'Services spécialisés pour les enfants en situation de handicap, avec des interventions adaptées à leurs besoins spécifiques.'
                    }].map(({ icon, title, content }, idx) => (
                        <div key={idx} className={`shadow-lg rounded-lg p-8 hover:scale-105 transform transition-transform ${colorMode === 'dark' ? 'bg-gray-700 border-gray-600 text-gray-300' : 'bg-white border-gray-200 text-gray-700'}`}>
                            <div className="flex items-center mb-4">
                                <div className="text-blue-500 text-4xl mr-4">
                                    {icon}
                                </div>
                                <h3 className="text-2xl font-bold">{title}</h3>
                            </div>
                            <p>{content}</p>
                        </div>
                    ))}
                </div>

                <h2 className={`text-4xl font-semibold mb-8 text-center ${colorMode === 'dark' ? 'text-blue-500' : 'text-blue-600'}`}>
                    Services d’Accompagnement pour Différents Bénéficiaires
                </h2>
                <div className="grid grid-cols-1 gap-8 mb-12">
                    <Accordion title="Personnes en situation de handicap" content="Nos services sont adaptés aux personnes nécessitant des soins spécifiques tels que la trachéotomie. Nous garantissons des soins rigoureux pour une autonomie optimale." />
                    <Accordion title="Personnes en dépendance" content="Nous assistons les personnes en état de dépendance avec des soins quotidiens personnalisés pour maximiser leur confort et autonomie." />
                    <Accordion title="Enfants avec des troubles du développement" content="Accompagnement pour enfants présentant des troubles comme l'autisme, avec des méthodes éducatives individualisées pour favoriser leur développement." />
                    <Accordion title="Personnes atteintes de traumatismes physiques" content="Prise en charge des personnes ayant subi un traumatisme physique avec rééducation et soutien psychologique." />
                    <Accordion title="Personnes atteintes de maladies rares et orphelines" content="Nous intervenons auprès des personnes atteintes de maladies rares avec des soins spécialisés pour chaque pathologie." />
                </div>
                <Link to="/contact">
                    <button className={`px-6 py-3 text-lg font-bold rounded-lg shadow-lg transition-colors ${colorMode === 'dark' ? 'bg-blue-600 text-white hover:bg-blue-500' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>Nous Contacter</button>
                </Link>
            </div>
        </>
    );
}

export default Presentation;
