import React from 'react';
import { Helmet } from 'react-helmet';
import { useColorMode } from '@chakra-ui/react'; // Import useColorMode

const MentionsLegales = () => {
    const { colorMode } = useColorMode(); // Get the current color mode

    return (
        <div className={`flex flex-col items-center p-8 ${colorMode === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
            <Helmet>
                <title>Mentions légales - Hetep Iaout Services</title>
                <link rel="canonical" href="https://www.hisvie.com/mentions-legales/" />
                <meta property="og:locale" content="fr_FR" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Mentions légales - Hetep Iaout Services" />
                <meta property="og:description" content="Mentions légales Informations légales Conformément aux dispositions des articles 6-III et 19 de la loi pour la Confiance dans l’Économie Numérique, nous vous informons que : Le présent site est édité et maintenu par l&rsquo;Agence WEB VALOREM – SIRET : 808351340 R.C.S. PARIS – dont le siège social est situé : 21 rue Descombes, 75017 &hellip; Mentions légales Lire la suite »" />
                <meta property="og:url" content="https://www.hisvie.com/mentions-legales/" />
                <meta property="og:site_name" content="Hetep Iaout Services" />
                <meta property="article:modified_time" content="2022-08-22T15:46:36+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:label1" content="Durée de lecture estimée" />
                <meta name="twitter:data1" content="5 minutes" />
                <meta name="keywords" content="mentions légales, Hetep Iout Service, informations légales, cookies, données personnelles" />
            </Helmet>
            <h1 className="text-3xl font-bold mb-4 text-center">Mentions légales</h1>
            <h2 className="text-2xl font-semibold mb-2 text-center">Informations légales</h2>
            <p className="text-center mb-4">
                Conformément aux dispositions des articles 6-III et 19 de la loi pour la Confiance dans l’Économie Numérique, nous vous informons que : 
                Le présent site est édité et maintenu par l'Agence WEB VALOREM – SIRET : 808351340 R.C.S. PARIS – dont le siège social est situé : 21 rue Descombes, 75017 Paris. 
                Prestataire assurant le stockage direct et permanent : ONLINE SAS, BP 438 - 75366 Paris CEDEX 08 - RCS Paris B 433 115 904 France
            </p>
            <h2 className="text-2xl font-semibold mb-2 text-center">Limitation de responsabilité</h2>
            <p className="text-center mb-4">
                Vous utilisez le site « www.hisvie.fr » sous votre seule et entière responsabilité. Hetep Iaout Services ne pourra être tenue pour responsable des dommages directs ou indirects, tels que, notamment, préjudice matériel, pertes de données ou de programme, préjudice financier, résultant de l’utilisation de ce site ou de sites qui lui sont liés.
            </p>
            <h2 className="text-2xl font-semibold mb-2 text-center">Informatique et Libertés</h2>
            <p className="text-center mb-4">
                Les traitements automatisés de données nominatives réalisés à partir du site internet « www.hisvie.fr » ont été traités en conformité avec les exigences de la loi n°78-17 du 6 janvier 1978 modifiée, relative à l’informatique, aux fichiers et aux libertés. 
                L’utilisateur est notamment informé que conformément à l’article 32 de la loi n°78-17 du 6 janvier 1978 modifiée, relative à l’informatique, aux fichiers et aux libertés, les informations qu’il communique par le biais des formulaires présents sur le site sont nécessaires pour répondre à sa demande et sont destinées à Hetep Iaout Services , en tant que responsable du traitement à des fins de gestion administrative et commerciale.
                L’utilisateur est informé qu’il dispose d’un droit d’accès, d’interrogation et de rectification qui lui permet, le cas échéant, de faire rectifier, compléter, mettre à jour, verrouiller ou effacer les données personnelles le concernant qui sont inexactes, incomplètes, équivoques, périmées ou dont la collecte, l’utilisation, la communication ou la conservation est interdite. 
                L’utilisateur dispose également d’un droit d’opposition au traitement de ses données pour des motifs légitimes ainsi qu’un droit d’opposition à ce que ces données soient utilisées à des fins de prospection commerciale. 
                L’ensemble de ces droits s’exerce auprès de Hetep Iaout Services par courrier accompagné d’une copie d’un titre d’identité comportant une signature à adresser à : Hetep Iaout Services , 21 rue descombes 75017 PARIS
            </p>
            <h2 className="text-2xl font-semibold mb-2 text-center">Mise à jour</h2>
            <p className="text-center mb-4">
                Hetep Iaout Services se réserve le droit de modifier et de mettre à jour, sans préavis, les présentes mentions légales et tous les éléments, produits présentés sur le site. 
                L’ensemble de ces modifications s’impose aux internautes qui doivent consulter les présentes Conditions Générales lors de chaque connexion.
            </p>
            <h2 className="text-2xl font-semibold mb-2 text-center">Données statistiques de suivi</h2>
            <p className="text-center mb-4">
                Nous utilisons les données de connexion pour nos statistiques de consultation (type de navigateur, nombre de visiteurs, rubriques visitées…) pour l’optimisation de notre site en terme de rubriques et de navigation, mais ces informations ne sont pas transmises à des tiers.
            </p>
            <h2 className="text-2xl font-semibold mb-2 text-center">Cookies</h2>
            <p className="text-center mb-4">
                L’utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation. 
                Le cookie est un bloc de données qui ne permet pas d’identifier les utilisateurs mais sert à enregistrer des informations relatives à la navigation de celui-ci sur le site. 
                Le paramétrage du logiciel de navigation permet d’informer de la présence de cookie et éventuellement, de la refuser de la manière décrite à l’adresse suivante : www.cnil.fr 
                L’utilisateur dispose de l’ensemble des droits susvisés s’agissant des données à caractère personnel communiquées par le biais des cookies dans les conditions indiquées ci-dessus. 
                Les utilisateurs du site internet « www.hisvie.fr» sont tenus de respecter les dispositions de la loi n°78-17 du 6 janvier 1978 modifiée, relative à l’informatique, aux fichiers et aux libertés, dont la violation est passible de sanctions pénales. 
                Ils doivent notamment s’abstenir, s’agissant des informations à caractère personnel auxquelles ils accèdent ou pourraient accéder, de toute collecte, de toute utilisation détournée d’une manière générale, de tout acte susceptible de porter atteinte à la vie privée ou à la réputation des personnes.
            </p>
            <h2 className="text-2xl font-semibold mb-2 text-center">Propriété Intellectuelle</h2>
            <p className="text-center mb-4">
                La structure générale ainsi que les logiciels, textes, images animées ou non, son savoir-faire et tous les autres éléments composant le site sont la propriété exclusive de Hetep Iaout Services. 
                Toute représentation totale ou partielle de ce site par quelle que personne que ce soit, sans l’autorisation expresse de Hetep Iaout Services est interdite et constituerait une contrefaçon sanctionnée par les articles L. 335-2 et suivants du Code de la Propriété Intellectuelle. 
                Il en est de même des bases de données figurant, le cas échéant sur le site Internet qui sont protégées par les dispositions de la loi du 1er juillet 1998 portant transposition dans le Code de la Propriété Intellectuelle de la directive du 11 mars 1996 relative à la protection juridique des bases de données, et dont Hetep Iaout Services est productrice. 
                La marque Hetep Iaout services, ses filiales et ses partenaires, ainsi que les logos figurant sur le site sont des marques protégées. 
                Toute reproduction totale ou partielle de ces marques ou de ces logos effectués à partir des éléments du site sans l’autorisation expresse de Hetep Iaout Services est donc prohibée au sens du Code de la Propriété Intellectuelle. 
                Les utilisateurs et les visiteurs du site internet ne peuvent mettre en place un hyperlien en direction de ce site sans l’autorisation expresse et préalable de Hetep Iaout Services . 
                Hetep Iaout Services ne saurait être responsable de l’accès par les utilisateurs via les liens hypertextes mis en place dans le cadre du site internet en direction d’autres ressources présentes sur le réseau. 
                Pour tout litige en relation avec l’utilisation du site "www.hisvie.fr" utilisateur reconnaît la compétence exclusive des tribunaux compétents. 
                Nous remercions les utilisateurs du site de nous faire part d’éventuelles omissions ou erreurs en adressant un message électronique à partir de la rubrique contact.
            </p>
        </div>
    );
};

export default MentionsLegales;
