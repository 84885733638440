import React, { useState } from 'react';
import Image1 from '../../../Asset/image1.png';
import Image2 from '../../../Asset/image2.png';
import Image3 from '../../../Asset/image3.png';
import Image4 from '../../../Asset/image6.png';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AccompagnementHandicap = () => {
  const [open, setOpen] = useState(null);

  const toggleAccordion = (index) => {
    setOpen(open === index ? null : index);
  };

  const services = [
    {
      title: "Aide aux gestes quotidiens",
      image: Image1,
      description: "Un intervenant accompagne les personnes en perte sévère ou légère d'autonomie, dans leurs gestes quotidiens à domicile. Cette assistance comprend notamment l'aide à la toilette, l'aide à l'habillage, l'aide à l'alimentation...",
    },
    {
      title: "Aide à la mobilité en extérieur",
      image: Image2,
      description: "Votre handicap rend vos déplacements extérieurs difficiles ? Un intervenant vous accompagne dans ces démarches. Nous disposons d'une flotte de véhicules pour PMR.",
    },
    {
      title: "Accompagnement à la vie sociale",
      image: Image3,
      description: "Handicap ne signifie pas nécessairement immobilité. Votre intervenant vous accompagne à vos activités de loisir, à vos sorties culturelles...",
    },
    {
      title: "Assistance administrative",
      image: Image4,
      description: "Votre intervenant vous assiste dans vos démarches administratives et informatiques. Il vous aide notamment à classer vos documents...",
    },
  ];

  return (
    <>
      <Helmet>
      <title>Personnes handicapées ou atteintes de maladies rares - Hetep Iaout Services</title>
        <link rel="canonical" href="https://www.hisvie.com/personnes-handicapees/" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Personnes handicapées ou atteintes de maladies rares - Hetep Iaout Services" />
        <meta property="og:description" content="Handicap &amp; Maladies rares Accueil Personnes handicapées Aide à domicile pour les personnes handicapées ou atteintes de maladies rares Nos solutions contre le handicap et les maladies rares Nos intervenants apportent leur assistance aux personnes en situation de handicap ou en incapacité temporaire. Nous sommes également spécialisés dans la prise en charge des personnes atteintes &hellip; Personnes handicapées ou atteintes de maladies rares Lire la suite »" />
        <meta property="og:url" content="https://www.hisvie.com/personnes-handicapees/" />
        <meta property="og:site_name" content="Hetep Iaout Services" />
        <meta property="article:modified_time" content="2024-09-30T13:53:33+00:00" />
        <meta property="og:image" content="https://www.hisvie.com/wp-content/uploads/2021/07/physiotherapist-helping-handicapped-man-in-wheelchair-during-recovery.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Durée de lecture estimée" />
        <meta name="twitter:data1" content="2 minutes" />
        <meta name="keywords" content="Accompagnement handicap, aide aux gestes quotidiens, aide à la mobilité, accompagnement à la vie sociale, assistance administrative" />
      </Helmet>

      <div className="p-6 sm:p-8 md:p-10 bg-none">
        <h1 className="text-2xl sm:text-3xl font-bold text-center mb-8">
          Accompagnement Handicap et Services pour Personnes en Perte d'Autonomie
        </h1>
        <div className="space-y-4">
          {services.map((service, index) => (
            <div key={index} className="border border-gray-300 rounded-lg hover:shadow-lg transition-shadow duration-300">
              <div 
                className="flex flex-col md:flex-row items-center md:items-start w-full cursor-pointer" 
                onClick={() => toggleAccordion(index)}
              >
                <img 
                  src={service.image} 
                  alt={service.title} 
                  className="w-full md:w-48 h-48 object-cover mb-4 md:mb-0 md:mr-4" 
                /> 
                <div className="flex-1 p-4">
                  <h2 className="text-xl sm:text-2xl font-semibold text-blue-500">
                    {service.title} - {index === 0 ? "Soins et Assistance Quotidienne" : index === 1 ? "Mobilité en Extérieur" : index === 2 ? "Vie Sociale et Loisirs" : "Support Administratif"}
                  </h2>
                  {open === index && (
                    <div className="border-t border-gray-300 mt-2 pt-2">
                      <p>{service.description}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-8 text-center">
          <Link to='/demande-de-devis'>
            <button className="bg-blue-400 text-white py-2 px-4 rounded hover:bg-blue-500 transition-colors duration-300">
              Obtenir un devis gratuit
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default AccompagnementHandicap;
