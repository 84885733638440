import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './component/header/header';
import Footer from './component/footer/footer';
import Home from './component/Home/home.js';
import Agence from './component/Agence/agence.js';
import Certif from './component/Certif/certif.js';
import Enfant from './component/Prestation/Enfant/enfant.js';
import Senior from './component/Prestation/Senior/senior.js';
import Contact from './component/contact/contact.js';
import Login from './component/connexion/connexion.js';
import Blog from './component/Blog/blog.js';
import AjouterArticle from './component/AjouterArticle.js/AjouterArticle.js';
import BlogDetail from './component/blogDetails/blogDetail.js';
import Devis from './component/Devis/devis.js';
import AccompagnementHandicap from './component/Prestation/Accompagnement/accompagnement_handicap.js';
import TransportPMR from './component/Prestation/TransportPmr/transport.js';
import Entretiens from './component/Prestation/Entretiens/entretien_domicile.js';
import AideFinanciere from './component/Tarif/Aide_financiere/aide_financiere.js';
import BaseTarifaire from './component/Tarif/Base_Tarifaire/base_tarifaire.js';
import NosRessources from './component/Ressource/ressource.js';
import RessourcesForm from './component/Ressource/ressourceForm.js';
import Presentation from './component/Presentation/presentation.js';
import NosPartenaires from './component/Partenaires/partenaires.js';
import Recrutement from './component/recrutement/recrutement.js';
import MentionLegal from './component/Mention/mention_legal.js'
import { ChakraProvider} from '@chakra-ui/react';
import theme from './theme';


function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/demande-de-devis' element={<Devis />} />
          <Route path="/agences" element={<Agence />} />
          <Route path="/enfants" element={<Enfant />} />
          <Route path="/agrements" element={<Certif />} />
          <Route path="/seniors" element={<Senior />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/connexion" element={<Login />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/recrutement" element={<Recrutement />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/ajouter-article" element={<AjouterArticle />} />
          <Route path="/personnes-handicapees" element={<AccompagnementHandicap />} />
          <Route path="/transport-de-pmr" element={<TransportPMR />} />
          <Route path="/entretien-du-domicile" element={<Entretiens />} />
          <Route path="/aide-financiere" element={<AideFinanciere />} />
          <Route path="/tarifs" element={<BaseTarifaire />} />
          <Route path="/NosRessources" element={<NosRessources />} />
          <Route path="/RessourcesForm" element={<RessourcesForm />} />
          <Route path="/Presentation" element={<Presentation />} />
          <Route path="/NosPartenaires" element={<NosPartenaires />} />
          <Route path="/mentions-legales" element={<MentionLegal />} />
        </Routes>
        <Footer />
      </Router>
    </ChakraProvider>
  );
}

export default App;
