import React from "react";
import { Link } from "react-router-dom"; 
import Certif1 from '../../Asset/certification-qualisap.jpg';
import Certif2 from '../../Asset/logo_caphandeo.jpg';
import Certif3 from '../../Asset/logo_services_personne.jpg';
import ImageCertif from '../../Asset/image8.png';
import { Helmet } from 'react-helmet';
import { useColorMode } from '@chakra-ui/react';

function Certif() {
    const { colorMode } = useColorMode(); // Récupération du mode couleur

    return (
        <>
        <Helmet>
        <title>Agréments - Hetep Iaout Services</title>
            <link rel="canonical" href="https://www.hisvie.com/agrements/" />
            <meta property="og:locale" content="fr_FR" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Agréments - Hetep Iaout Services" />
            <meta property="og:description" content="Agréments Accueil Agréments Nos agréments Certification Cap&rsquo;Handéo  » Cap&rsquo;Handéo, ce sont des labels et une certification de service. Fondés sur des référentiels rédigés par les personnes en situation de handicap, mais aussi par des professionnels (des secteurs de l&rsquo;aide à domicile, des transports, des aides techniques), les labels et certification Cap&rsquo;Handéo répertorient un ensemble d&rsquo;exigences &hellip; Agréments Lire la suite »" />
            <meta property="og:url" content="https://www.hisvie.com/agrements/" />
            <meta property="og:site_name" content="Hetep Iaout Services" />
            <meta property="article:modified_time" content="2024-09-30T14:09:26+00:00" />
            <meta property="og:image" content="https://www.hisvie.com/wp-content/uploads/2021/06/senior-woman-greeting-female-care-worker-making-home-visit-300x220.jpg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:label1" content="Durée de lecture estimée" />
            <meta name="twitter:data1" content="3 minutes" />
            <meta name="keywords" content="Hetep Iout Service, certifications, Cap'Handéo, Qualisap, services à la personne, certification qualité, accompagnement handicap, assistance à domicile, services certifiés, sécurité, bien-être, ANSP, services pour personnes âgées, services pour personnes handicapées"/>
        </Helmet>
            <div className="certifPresentation flex flex-col items-center text-center p-8 mb-10">
                <h1 className={`text-4xl font-bold mb-2 ${colorMode === 'dark' ? 'text-gray-300' : 'text-black'}`} style={{ fontFamily: 'Georgia, serif' }}>
                    Nos Certifications
                </h1>
                <p className={`text-lg ${colorMode === 'dark' ? 'text-gray-400' : 'text-gray-700'} max-w-3xl`} style={{ fontFamily: 'Arial, sans-serif' }}>
                    À HIS, nous avons des certifications qui nous rendent plus légitimes et nous donnent du crédit auprès de vous.
                </p>
            </div>

            <div className="certifContainer grid grid-cols-1 lg:grid-cols-2 gap-8 px-4 lg:px-16 mb-16" style={{ fontFamily: 'Arial, sans-serif' }}>
                <div className={`certif1 p-6 flex flex-col items-center rounded-lg shadow-lg ${colorMode === 'dark' ? 'bg-gray-700' : 'bg-white'}`}>
                    <h2 className={`text-2xl font-semibold mb-4 text-center ${colorMode === 'dark' ? 'text-gray-300' : 'text-black'}`}>Certification Cap'Handéo</h2>
                    <p className={`mb-4 text-center ${colorMode === 'dark' ? 'text-gray-400' : 'text-gray-700'}`}>
                        Cap’Handéo, ce sont des labels et une certification de service. Fondés sur des référentiels rédigés par des personnes en situation de handicap, ces certifications répertorient un ensemble d’exigences incontournables.
                    </p>
                    <iframe
                        width="100%"
                        height="309"
                        src="https://www.youtube.com/embed/V4UzoFVjio0"
                        title="HANDEO (Animation sous-titrée)"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    />
                </div>
                <div className={`certif2 p-6 flex flex-col items-center rounded-lg shadow-lg ${colorMode === 'dark' ? 'bg-gray-700' : 'bg-white'}`}>
                    <h2 className={`text-2xl font-semibold mb-4 text-center ${colorMode === 'dark' ? 'text-gray-300' : 'text-black'}`}>Certification Qualisap v4</h2>
                    <p className={`mb-4 text-center ${colorMode === 'dark' ? 'text-gray-400' : 'text-gray-700'}`}>
                        Les structures de services à la personne placent l’humain au cœur de leurs préoccupations. Afin de mesurer la qualité des prestations délivrées, le référentiel de certification QUALISAP fixe des critères objectifs.
                    </p>
                    <img
                        src={ImageCertif}
                        alt="Certif_Image"
                        className="w-full rounded-lg"
                    />
                </div>
            </div>

            <div className={`endpage text-center p-8 ${colorMode === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-gray-100 text-black'}`} style={{ fontFamily: 'Arial, sans-serif' }}>
                <h2 className="text-3xl font-bold mb-6">Pourquoi nous faire confiance ?</h2>
                <p className="text-lg mb-8">
                    HIS est une agence certifiée, gage de notre engagement pour la qualité des services que nous offrons à nos clients.
                </p>
                <div className="flex flex-col md:flex-row justify-center space-y-8 md:space-y-0 md:space-x-16 mb-8">
                    <div className="flex flex-col items-center">
                        <img
                            src={Certif2}
                            alt="Logo Cap'Handéo"
                            className="h-28 w-28 mb-4 shadow-lg transform transition-transform hover:scale-105"
                        />
                        <a
                            href="https://www.handeo.fr"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:underline font-medium text-lg"
                        >
                            Certification Cap'Handéo
                        </a>
                    </div>
                    <div className="flex flex-col items-center">
                        <img
                            src={Certif1}
                            alt="Logo Qualisap"
                            className="h-28 w-28 mb-4 shadow-lg transform transition-transform hover:scale-105"
                        />
                        <a
                            href="https://www.servicesalapersonne.gouv.fr/espace-pro/outils/la-certification-de-service"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:underline font-medium text-lg"
                        >
                            Certification Qualisap
                        </a>
                    </div>
                    <div className="flex flex-col items-center">
                        <img
                            src={Certif3}
                            alt="Logo Services à la Personne"
                            className="h-28 w-28 mb-4 shadow-lg transform transition-transform hover:scale-105"
                        />
                        <a
                            href="https://www.servicesalapersonne.gouv.fr"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:underline font-medium text-lg"
                        >
                            Autorisation ANSP
                        </a>
                    </div>
                </div>

                <Link to="/NosRessources">
                    <button className="bg-blue-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-blue-600 transition-colors">
                        Voir Nos Ressources
                    </button>
                </Link>
            </div>
        </>
    );
}

export default Certif;
