import React, { useState } from 'react';
import axios from 'axios';
import { FaEnvelope, FaPhone, FaFileUpload, FaBriefcase, FaBroom, FaChild, FaHandHoldingMedical, FaCar, FaUserNurse, FaAccessibleIcon, FaPaperPlane } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useColorMode } from '@chakra-ui/react'; // Import useColorMode

const QualityCard = ({ title, icon, qualities, colorMode }) => (
  <div className={`p-6 shadow-lg rounded-lg transform hover:scale-105 transition duration-300 hover:shadow-2xl ${colorMode === 'dark' ? 'bg-gray-700 text-white' : 'bg-white text-gray-700'}`}>
    <div className="flex justify-center mb-4">
      {icon}
    </div>
    <h3 className={`text-xl font-semibold text-blue-600 text-center mb-3 ${colorMode === 'dark' ? 'text-white' : 'text-blue-600'}`}>{title}</h3>
    <ul className="space-y-2">
      {qualities.map((quality, index) => (
        <li key={index} className="text-sm">{quality}</li>
      ))}
    </ul>
  </div>
);

const RecrutementPage = () => {
  const { colorMode } = useColorMode(); // Get the current color mode
  const [formData, setFormData] = useState({
    jobTitle: '',
    email: '',
    phone: '',
    cv: null,
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'file' ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.cv) {
        toast.error("Veuillez joindre un fichier CV.");
        return;
    }

    try {
        // Convertir le fichier CV en base64
        const cvContent = await fileToBase64(formData.cv);

        // Envoyer les données au backend
        const response = await axios.post('/api/email/send-email', {
            senderEmail: 'recrutement@his.com',
            receiverEmail: 'his@donnerlavieauxannees.com',
            subject: `Candidature pour le poste: ${formData.jobTitle}`,
            htmlContent: `
                <h3>Nouvelle candidature reçue</h3>
                <p><strong>Poste:</strong> ${formData.jobTitle}</p>
                <p><strong>Email du candidat:</strong> ${formData.email}</p>
                <p><strong>Téléphone:</strong> ${formData.phone}</p>
            `,
            attachment: {
                content: cvContent,
                name: formData.cv.name,
            },
        });

        if (response.status === 201) {
            toast.success("Votre candidature a été envoyée avec succès !");
            setFormData({
                jobTitle: '',
                email: '',
                phone: '',
                cv: null,
            });
        }
    } catch (error) {
        console.error("Erreur lors de l'envoi de la candidature :", error);
        toast.error("Une erreur est survenue lors de l'envoi de la candidature.");
    }
};


  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const qualitiesPerJob = [
    {
      title: 'Assistant(e) ménager(ère)',
      icon: <FaBroom className="text-4xl text-blue-500" />,
      qualities: ['Responsable du nettoyage et de l\'entretien des locaux.', 'Excellentes compétences en nettoyage', 'Précision et organisation', 'Discrétion et fiabilité']
    },
    {
      title: 'Garde d\'enfant(s)',
      icon: <FaChild className="text-4xl text-blue-500" />,
      qualities: ['Prend soin des enfants, veille à leur sécurité.', 'Responsabilité et patience', 'Capacité à encadrer et divertir', 'Premiers secours de base']
    },
    {
      title: 'Aide-soignant(e)',
      icon: <FaHandHoldingMedical className="text-4xl text-blue-500" />,
      qualities: ['Apporte une assistance médicale de base.', 'Empathie et patience', 'Connaissances médicales de base', 'Aptitudes en soins de santé']
    },
    {
      title: 'Chauffeur(se)',
      icon: <FaCar className="text-4xl text-blue-500" />,
      qualities: ['Assure le transport sécurisé et ponctuel des clients.', 'Bonnes compétences de conduite', 'Ponctualité et fiabilité', 'Bonne connaissance des itinéraires']
    },
    {
      title: 'Auxiliaire de vie',
      icon: <FaUserNurse className="text-4xl text-blue-500" />,
      qualities: ['Fournit un soutien quotidien aux personnes âgées.', 'Sens de l’écoute', 'Aide à l’autonomie', 'Respect de la dignité des patients']
    },
    {
      title: 'Intervenant(e) pour personne en situation de handicap',
      icon: <FaAccessibleIcon className="text-4xl text-blue-500" />,
      qualities: ['Accompagne les personnes en situation de handicap.', 'Patience et compréhension', 'Adaptabilité aux besoins spéciaux', 'Capacité à accompagner dans diverses tâches']
    }
  ];

  return (
    <div className={`flex flex-col w-full ${colorMode === 'dark' ? 'bg-gray-800 text-white' : 'bg-gradient-to-b from-blue-50 to-blue-100'} min-h-screen p-10`}>
      <Helmet>
        <title>Recrutement - Hetep Iaout Services</title>
        <link rel="canonical" href="https://www.hisvie.com/recrutement/" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Recrutement - Hetep Iaout Services" />
        <meta property="og:description" content="Recrutement Nous recrutons régulièrement des intervenants et recherchons actuellement les profils ci-dessous. Notre processus de recrutement 01 02 03 Sélectionnez un poste Sélectionnez le poste pour lequel vous souhaitez postuler. Remplissez notre formulaire Remplissez notre formulaire de candidature en prenant bien le soin de rédiger une lettre de motivation. Tests &amp; Entretiens d&#039;embauche Vous devrez &hellip; Recrutement Lire la suite »" />
        <meta property="og:url" content="https://www.hisvie.com/recrutement/" />
        <meta property="og:site_name" content="Hetep Iaout Services" />
        <meta property="article:modified_time" content="2022-07-04T09:37:25+00:00" />
        <meta property="og:image" content="https://www.hisvie.com/wp-content/uploads/2021/07/job-recruitment-interview-1024x680.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Durée de lecture estimée" />
        <meta name="twitter:data1" content="3 minutes" />
      </Helmet>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar />
      
      <header className="text-center w-full mb-12">
        <h1 className={`text-5xl font-extrabold ${colorMode === 'dark' ? 'text-white' : 'text-gray-800'} mb-4`}>Rejoignez Hetep Iout Service</h1>
        <p className={`text-xl ${colorMode === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>Découvrez nos opportunités de carrière dans le service à la personne.</p>
      </header>

      <section className="w-full mb-12">
        <h2 className={`text-3xl font-semibold ${colorMode === 'dark' ? 'text-white' : 'text-gray-800'} text-center mb-6`}>Postes Ouverts</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {qualitiesPerJob.map((job, index) => (
            <QualityCard key={index} title={job.title} icon={job.icon} qualities={job.qualities} colorMode={colorMode} />
          ))}
        </div>
      </section>

      <section className={`w-full shadow-lg rounded-lg p-8 ${colorMode === 'dark' ? 'bg-gray-700' : 'bg-white'}`}>
        <h2 className={`text-3xl font-semibold ${colorMode === 'dark' ? 'text-white' : 'text-gray-800'} text-center`}>Soumettre votre Candidature</h2>
        <form onSubmit={handleSubmit} className="mt-6 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="jobTitle" className={`flex items-center text-lg font-medium ${colorMode === 'dark' ? 'text-white' : 'text-gray-700'}`}>
                <FaBriefcase className="mr-2 text-blue-600" />
                Intitulé du poste
              </label>
              <input
                type="text"
                id="jobTitle"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
                required
                placeholder="Ex: Garde d'enfant"
                className={`mt-1 block w-full border border-gray-300 rounded p-3 text-lg ${colorMode === 'dark' ? 'bg-gray-600 text-white' : 'bg-white text-gray-900'}`}
              />
            </div>
            <div>
              <label htmlFor="email" className={`flex items-center text-lg font-medium ${colorMode === 'dark' ? 'text-white' : 'text-gray-700'}`}>
                <FaEnvelope className="mr-2 text-blue-500" />
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className={`mt-1 block w-full border border-gray-300 rounded p-3 text-lg ${colorMode === 'dark' ? 'bg-gray-600 text-white' : 'bg-white text-gray-900'}`}
              />
            </div>
            <div>
              <label htmlFor="phone" className={`flex items-center text-lg font-medium ${colorMode === 'dark' ? 'text-white' : 'text-gray-700'}`}>
                <FaPhone className="mr-2 text-blue-500" />
                Numéro de téléphone
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                className={`mt-1 block w-full border border-gray-300 rounded p-3 text-lg ${colorMode === 'dark' ? 'bg-gray-600 text-white' : 'bg-white text-gray-900'}`}
              />
            </div>
            <div>
              <label htmlFor="cv" className={`flex items-center text-lg font-medium ${colorMode === 'dark' ? 'text-white' : 'text-gray-700'}`}>
                <FaFileUpload className="mr-2 text-blue-500" />
                CV
              </label>
              <input
                type="file"
                id="cv"
                name="cv"
                onChange={handleChange}
                accept=".pdf,.doc,.docx"
                required
                className={`mt-1 block w-full border border-gray-300 rounded p-3 text-lg ${colorMode === 'dark' ? 'bg-gray-600 text-white' : 'bg-white text-gray-900'}`}
              />
            </div>
          </div>
          <button type="submit" className={`w-full flex justify-center items-center ${colorMode === 'dark' ? 'bg-blue-500 text-white' : 'bg-blue-500 text-white'} rounded p-3 text-lg font-semibold hover:bg-blue-700 transition duration-300`}>
            <FaPaperPlane className="mr-2" /> Envoyer
          </button>
        </form>
      </section>
    </div>
  );
};

export default RecrutementPage;
