import React, { useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi"; 
import { FaHouseUser, FaChair, FaToilet, FaRegClipboard, FaQuestionCircle } from "react-icons/fa"; 
import cleaningImage from '../../../Asset/entretiens.jpg'; 
import EntretiensImg from '../../../Asset/Entretien_Logement.jpg';
import { Link } from 'react-router-dom'; 
import { Helmet } from 'react-helmet';
import { useColorMode } from '@chakra-ui/react';

const MénageDomicile = () => {
    const { colorMode } = useColorMode();
    const [openIndex, setOpenIndex] = useState(null);

    const services = [
        {
            title: "Ménage domestique régulier",
            description: "Afin de maintenir la propreté de votre logement, nous vous proposons un service de nettoyage hebdomadaire de quelques heures. L’ensemble de votre logement est régulièrement nettoyé, garantissant ainsi une hygiène optimale et une souplesse totale.",
        },
        {
            title: "Ménage régulier",
            description: "Ce type de ménage est proposé pour des missions à caractère spécial, telles que le rangement avec classement, etc.",
        },
        {
            title: "Ménage lourd",
            description: "Ce type de ménage nécessite l’utilisation de matériel et de produits professionnels. La durée minimale de la prestation est de 10 heures.",
        },
        {
            title: "Ménage occasionnel",
            description: "HIS vous propose des interventions ponctuelles et sans engagement. Nous intervenons uniquement sur commande.",
        },
    ];

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <>
            <Helmet>
            <title>Entretien du domicile - Hetep Iaout Services</title>
                <link rel="canonical" href="https://www.hisvie.com/entretien-du-domicile/" />
                <meta property="og:locale" content="fr_FR" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Entretien du domicile - Hetep Iaout Services" />
                <meta property="og:description" content="Entretien de votre domicile Devis personnalisé Une assistance pour le ménage de votre domicile Assurer l&rsquo;hygiène et l&rsquo;entretien de son logement peut être compliqué dans les situations de handicap. Hetep Iaout Services vous assiste dans ces tâches. Ménage domestique régulier Afin de maintenir la propreté de votre logement, nous vous proposons un service de nettoyage &hellip; Entretien du domicile Lire la suite »" />
                <meta property="og:url" content="https://www.hisvie.com/entretien-du-domicile/" />
                <meta property="og:site_name" content="Hetep Iaout Services" />
                <meta property="article:modified_time" content="2024-09-30T13:55:13+00:00" />
                <meta property="og:image" content="https://www.hisvie.com/wp-content/uploads/elementor/thumbs/vacuum-cleaner-on-the-carpet-pceg8qrlqysspvz95hhxrcgwcv035qkk3rajnf6i5s.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:label1" content="Durée de lecture estimée" />
                <meta name="twitter:data1" content="3 minutes" />
                <meta name="keywords" content="Ménage domicile, nettoyage à domicile, services de ménage, entretien de maison, ménage domestique, ménage régulier, ménage lourd, ménage occasionnel, assistance ménage" />
            </Helmet>
            <div className={`mx-auto ${colorMode === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
                {/* Bannière avec image */}
                <div className="relative w-full h-48 sm:h-64 md:h-80 mb-6 overflow-hidden">
                    <img 
                        src={EntretiensImg} 
                        alt="Bannière de ménage" 
                        className="w-full h-full object-cover opacity-70" 
                    />
                    <h1 className="absolute inset-0 flex items-center justify-center text-2xl sm:text-3xl md:text-4xl font-bold text-white z-10 text-center px-4">
                        Entretien et Ménage à Domicile pour Votre Confort
                    </h1>
                </div>

                <div className="flex flex-col md:flex-row items-center p-4 sm:p-6 mb-10 space-y-6 md:space-y-0 md:space-x-6">
                    <div className="w-full md:w-1/2">
                        <img src={cleaningImage} alt="Vacuum Cleaner" className="w-full h-auto rounded-lg shadow-lg" />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-6">
                        <h2 className="text-2xl sm:text-3xl font-bold text-blue-500 text-center md:text-left mb-4">
                            Assistance Personnalisée pour l’Entretien de Votre Domicile
                        </h2>
                        <p className="text-base sm:text-lg mb-6">
                            Assurer l’hygiène et l’entretien de son logement peut être compliqué dans les situations de handicap. Hetep Iout Services vous assiste dans ces tâches.
                        </p>

                        <div className="flex flex-col w-full space-y-4">
                            {services.map((service, index) => (
                                <div className={`border border-gray-300 rounded-lg shadow-lg ${colorMode === 'dark' ? 'bg-gray-700' : 'bg-white'}`} key={index}>
                                    <div className="flex justify-between items-center p-4 cursor-pointer" onClick={() => handleToggle(index)}>
                                        <h3 className="text-lg font-semibold">{service.title}</h3>
                                        {openIndex === index ? (
                                            <HiChevronUp className="text-blue-500" />
                                        ) : (
                                            <HiChevronDown className="text-blue-500" />
                                        )}
                                    </div>
                                    <div className={`p-4 transition-all duration-300 ${openIndex === index ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}>
                                        {openIndex === index && (
                                            <>
                                                <p>{service.description}</p>
                                                <div className="mt-4 text-center">
                                                    <Link to="/contact">
                                                        <button className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-700">
                                                            Nous contacter
                                                        </button>
                                                    </Link>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className={`mt-10 w-full p-6 ${colorMode === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-gray-200 text-gray-900'}`}>
                    <h2 className="text-2xl sm:text-3xl font-bold text-blue-500 text-center mb-4">Nettoyage de Votre Logement pour un Confort Optimal</h2>
                    <p className="text-base sm:text-lg text-center mb-6">
                        Vous n’êtes pas en mesure d’effectuer le ménage de votre logement par vous-même? Un handicap ou un âge avancé font obstacle à l’accomplissement de cette tâche? Nos intervenants peuvent se charger de l’entretien de votre lieu de vie.
                    </p>

                    <div className={`border border-gray-300 rounded-lg shadow-lg p-6 ${colorMode === 'dark' ? 'bg-gray-700' : 'bg-white'}`}>
                        <h3 className="text-lg sm:text-xl font-bold text-center mb-4">Nos Services de Nettoyage</h3>
                        <div className="flex flex-wrap justify-around space-y-4 md:space-y-0 md:space-x-4">
                            <div className="text-center w-1/2 sm:w-auto">
                                <FaHouseUser className="text-blue-500 mb-2 mx-auto" size={40} />
                                <span>Nettoyage de votre maison</span>
                            </div>
                            <div className="text-center w-1/2 sm:w-auto">
                                <FaChair className="text-blue-500 mb-2 mx-auto" size={40} />
                                <span>Nettoyage de votre mobilier</span>
                            </div>
                            <div className="text-center w-1/2 sm:w-auto">
                                <FaToilet className="text-blue-500 mb-2 mx-auto" size={40} />
                                <span>Nettoyage de vos sanitaires</span>
                            </div>
                            <div className="text-center w-1/2 sm:w-auto">
                                <FaRegClipboard className="text-blue-500 mb-2 mx-auto" size={40} />
                                <span>Ménage de votre appartement</span>
                            </div>
                        </div>
                    </div>

                    <div className={`mt-10 w-full p-6 ${colorMode === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-blue-200 text-gray-900'}`}>
                        <div className="text-center">
                            <FaQuestionCircle className="text-blue-500 opacity-50 mb-4 mx-auto" size={50} />
                        </div>
                        <h2 className="text-2xl sm:text-3xl font-bold text-blue-500 text-center mb-4">
                            Faites Appel à Nous pour le Nettoyage de Votre Domicile
                        </h2>
                        <p className="text-base sm:text-lg text-center mb-6">
                            Remplissez une demande de devis pour avoir une estimation.
                        </p>
                        <div className="text-center">
                            <Link to="/demande-de-devis">
                                <button className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
                                    Demande de devis
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MénageDomicile;
