import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Icônes de flèche
import { Helmet } from 'react-helmet';
import { useColorMode } from '@chakra-ui/react';

function Accordion({ title, content }) {
    const [isOpen, setIsOpen] = useState(false);
    const { colorMode } = useColorMode();

    return (
        <div className={`border rounded-lg mb-4 shadow-lg hover:shadow-xl transition-shadow ${colorMode === 'dark' ? 'border-gray-700 bg-gray-800' : 'border-blue-200 bg-white'}`}>
            <button 
                className={`w-full text-left p-5 rounded-lg focus:outline-none ${colorMode === 'dark' ? 'bg-gray-800 hover:bg-gray-700' : 'bg-gradient-to-r from-blue-400 to-white hover:bg-blue-500'}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <h3 className={`flex items-center justify-between text-lg font-semibold ${colorMode === 'dark' ? 'text-white' : 'text-blue-600'}`}>
                    {title}
                    <span className={`ml-auto transform ${isOpen ? 'rotate-180' : ''} transition-transform`}>
                        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
                    </span>
                </h3>
            </button>
            {isOpen && (
                <div className={`p-4 border-t rounded-b-lg ${colorMode === 'dark' ? 'bg-gray-700 border-gray-600 text-gray-300' : 'bg-white border-blue-200 text-gray-700'}`}>
                    <p>{content}</p>
                </div>
            )}
        </div>
    );
}

const Agence = () => {
    const agences = [
        { id: 'paris', title: 'Paris', content: '15 boulevard Pereire, 75017 Paris' },
        { id: 'la-courneuve', title: 'La Courneuve', content: '8, place Georges Braque, 93120 La Courneuve' },
        { id: 'saint-denis', title: 'Saint Denis', content: '187 Boulevard Anatole France, 93200 Saint Denis' },
        { id: 'lille', title: 'Lille', content: '69, rue Gambetta, 59830 Cysoing' },
        { id: 'colombe', title: 'Colombe', content: '91 Rue Gabriel Péri 92700 Colombe' },
        { id: 'nangis', title: 'Nangis', content: '19 rue des fontaines, 77370 Nangis' },
        { id: 'domont', title: 'Domont', content: '2 allées des pentelieres, 95330 Domont' },
    ];

    const contactInfo = {
        phone: '01 43 52 64 23',
        email: 'his@donnerlavieauxannees.com', 
    };

    const { colorMode } = useColorMode();
    const ldJson = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Hisvie Agence La Courneuve",
        "image": "https://www.hisvie.com/static/media/logo.3ba0d0f510c062604afd.png", 
        "url": "https://www.hisvie.com/agences",
        "telephone": "+33 1 23 45 67 89",
        "description": "Hisvie Agence est spécialisée dans les soins à domicile pour personnes âgées et en situation de handicap, avec une attention particulière pour les besoins spécifiques des patients.",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "8 Place Georges Braques",
          "addressLocality": "La Courneuve",
          "postalCode": "93120",
          "addressCountry": "FR"
        },
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+33 1 23 45 67 89",
          "contactType": "customer service",
          "areaServed": "FR",
          "availableLanguage": ["French"]
        },
      };

    return (
        <>
            <Helmet>
            <title>Agences - Hetep Iaout Services</title>
                <link rel="canonical" href="https://www.hisvie.com/agences/" />
                <meta property="og:locale" content="fr_FR" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Agences - Hetep Iaout Services" />
                <meta property="og:description" content="Vous cherchez une agence ? Agences HIS Vous résidez en Ile-de-France ? Il est fort probable qu&rsquo;une de nos agences soit localisée à proximité de votre domicile. Nous avons également une agence située dans les Hauts- de-France. Paris 15, boulevard Pereire 75017 Paris   La Courneuve 8, place Georges Braque 93120 La Courneuve Lille Agence &hellip; Agences Lire la suite »" />
                <meta property="og:url" content="https://www.hisvie.com/agences/" />
                <meta property="og:site_name" content="Hetep Iaout Services" />
                <meta property="article:modified_time" content="2022-12-02T15:26:21+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:label1" content="Durée de lecture estimée" />
                <meta name="twitter:data1" content="1 minute" />
                <meta name="keywords" content="Hetep Iout Service, agences Ile-de-France, agences Hauts-de-France, aide à domicile, assistance à domicile" />
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                      __html: JSON.stringify(ldJson),
                    }}
                />
            </Helmet>
            
            <div className="flex flex-col items-center w-full max-w-4xl mx-auto p-6">
                <h1 className={`text-4xl font-bold text-center mb-4 ${colorMode === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                    NOS AGENCES - HETEP IOUT SERVICE
                </h1>
                
                <h2 className={`text-2xl font-semibold text-center mb-2 ${colorMode === 'dark' ? 'text-gray-300' : 'text-gray-900'}`}>
                    Agences de services à domicile en Ile-de-France et Hauts-de-France
                </h2>
                <hr className={`w-full border-t-2 mb-4 ${colorMode === 'dark' ? 'border-gray-700' : 'border-gray-300'}`} />
                
                <p className={`text-center text-lg mb-6 ${colorMode === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                    Vous résidez en Ile-de-France ? Il est fort probable qu’une de nos agences soit localisée à proximité de votre domicile. Nous avons également une agence située dans les Hauts-de-France.
                </p>
                
                <h2 className={`text-2xl font-semibold mb-4 ${colorMode === 'dark' ? 'text-gray-300' : 'text-gray-900'}`}>
                    Liste de nos agences en France
                </h2>
                <div className="flex flex-col w-full space-y-4">
                    {agences.map((agence) => (
                        <Accordion key={agence.id} title={agence.title} content={`Adresse: ${agence.content}`} />
                    ))}
                </div>

                {/* Section pour les coordonnées de contact */}
                <div className={`mt-6 p-4 rounded-lg shadow-lg text-center ${colorMode === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-blue-100 text-gray-900'}`}>
                    <h2 className={`text-2xl font-semibold mb-4 ${colorMode === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                        Pour nous contacter
                    </h2>
                    <h3 className={`text-lg font-semibold ${colorMode === 'dark' ? 'text-gray-300' : 'text-gray-900'}`}>
                        Coordonnées principales
                    </h3>
                    <p><strong>Téléphone:</strong> {contactInfo.phone}</p>
                    <p>
                        <strong>Email:</strong> 
                        <a href={`mailto:${contactInfo.email}`} className={`hover:underline ${colorMode === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>
                            {contactInfo.email}
                        </a>
                    </p>
                </div>
            </div>
        </>
    );
};

export default Agence;
