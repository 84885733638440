import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  components: {
    Box: {
      variants: {
        transparent: {
          bg: 'transparent',
          boxShadow: 'none',
        },
        bordered: {
          border: '1px solid',
          borderColor: { base: 'gray.300', _dark: 'gray.600' }, // Utilisation de _dark pour le mode sombre
          borderRadius: 'md',
        },
      },
    },
    Card: {
      variants: {
        transparent: {
          bg: 'transparent',
          boxShadow: 'none',
        },
        bordered: {
          border: '1px solid',
          borderColor: { base: 'gray.300', _dark: 'gray.600' },
          borderRadius: 'md',
        },
      },
    },
  },
  styles: {
    global: (props) => ({
      '.transparent': {
        backgroundColor: 'transparent !important',
        boxShadow: 'none !important',
      },
      '.bordered': {
        border: '1px solid',
        borderColor: props.colorMode === 'dark' ? 'gray.600' : 'gray.300',
        borderRadius: '8px',
      },
    }),
  },
});

export default theme;
