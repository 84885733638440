import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Importer le composant Link
import axios from "axios"; // Assurez-vous d'importer axios
import image1 from "../../Asset/image1.png"; // Assurez-vous de spécifier le bon chemin vers l'image
import image6 from "../../Asset/image6.png"; // Chemin vers image6
import image7 from "../../Asset/image7.png"; // Chemin vers image7
import { FaCheckCircle, FaPaperPlane, FaClock, FaUser, FaChevronDown } from 'react-icons/fa'; // Importer les icônes
import { Helmet } from 'react-helmet';
import { useColorMode } from '@chakra-ui/react';
import SafeContent from '../Securite/safeContent';


function Home() {
    const { colorMode } = useColorMode();
    const [showServices, setShowServices] = useState(false);
    const [recentBlogs, setRecentBlogs] = useState([]);

    const toggleServices = () => {
        setShowServices(!showServices);
    };

    const fetchRecentBlogs = async () => {
        try {
            const response = await axios.get('https://www.hisvie.com/backend/api/blog'); // Mettez à jour l'URL si nécessaire
            if (response.status === 200) {
                const sortedBlogs = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setRecentBlogs(sortedBlogs.slice(0, 2)); // Prendre les 2 plus récents
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des blogs:', error);
        }
    };

    useEffect(() => {
        fetchRecentBlogs();
    }, []);
    const jsonLd = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Hisvie",
        "url": "https://www.hisvie.com",
        "logo": "https://www.hisvie.com/static/media/logo.3ba0d0f510c062604afd.png",
        "description": "Agence spécialisée dans les soins à domicile pour personnes âgées et en situation de handicap.",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "8 Place Georges Braques",
          "addressLocality": "La Courneuve",
          "postalCode": "93120",
          "addressCountry": "FR"
        },
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+33 1 43 52 64 23",
          "contactType": "customer service",
          "areaServed": "FR",
          "availableLanguage": ["French"]
        }
      };

    return (    
        <>
        <Helmet>
        <title>Hetep Iaout Services – Service à la personne spécialisé</title>
            <meta name="description" content="Leader français du Service à la personne. Spécialiste du handicap lourd et des maladies rares ou orphelines." />
            <link rel="canonical" href="https://www.hisvie.com/" />
            <meta property="og:locale" content="fr_FR" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Hetep Iaout Services – Service à la personne spécialisé" />
            <meta property="og:description" content="Leader français du Service à la personne. Spécialiste du handicap lourd et des maladies rares ou orphelines." />
            <meta property="og:url" content="https://www.hisvie.com/" />
            <meta property="og:site_name" content="Hetep Iaout Services" />
            <meta property="article:modified_time" content="2024-09-30T14:05:19+00:00" />
            <meta property="og:image" content="https://www.hisvie.com/wp-content/uploads/2021/06/cropped-view-of-senior-man-holding-hand-of-handicapped-ill-wife-in-wheelchair.jpg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:label1" content="Durée de lecture estimée" />
            <meta name="twitter:data1" content="3 minutes" />
            <meta name="keywords" content="Services à la personne, Accompagnement, Aide aux seniors, Garde d'enfants, Handicap, Services personnalisés, Assistance administrative, Transport de personnes à mobilité réduite, Services 24/7, Agence certifiée, Santé et bien-être, Services de qualité, Équipe spécialisée, Soutien familial, Vie à domicile"/>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
            />
        </Helmet>
        <div className="banniereOne flex flex-col items-center justify-center text-white p-4 sm:p-8 md:p-10 h-[70vh] relative">
    <img src={image1} alt="Bannière" className="absolute inset-0 object-cover w-full h-full filter brightness-50" />
    
    <div className="relative z-10 text-center max-w-full px-4 sm:px-8 md:px-10">
        <h1 className="text-2xl sm:text-4xl md:text-5xl font-bold mb-4">Spécialiste du handicap lourd et des maladies rares ou orphelines</h1>
        
        <div className="banniereOneLi flex flex-col sm:flex-row sm:flex-wrap justify-center mb-6">
            <ul className="mr-0 sm:mr-8 mb-4 sm:mb-0">
                <li className="mb-2 flex items-center"><FaCheckCircle className="mr-2" />Garde d'enfants</li>
                <li className="mb-2 flex items-center"><FaCheckCircle className="mr-2" />Aide aux séniors</li>
                <li className="mb-2 flex items-center"><FaCheckCircle className="mr-2" />Handicap</li>
            </ul>
            <ul>
                <li className="mb-2 flex items-center"><FaCheckCircle className="mr-2" />Entretiens du domicile</li>
                <li className="mb-2 flex items-center"><FaCheckCircle className="mr-2" />Assistance informatique</li>
                <li className="mb-2 flex items-center"><FaCheckCircle className="mr-2" />Assistance administrative</li>
            </ul>
        </div>
        
        <Link to="/demande-de-devis">
            <button className="bg-yellow-500 hover:bg-yellow-400 text-black font-semibold py-2 px-6 rounded transition duration-300">
                Devis
            </button>
        </Link>
    </div>
</div>


            {/* Section Qui sommes-nous */}
            <div
                className={`qui-sommes-nous p-10 text-center ${
                colorMode === 'dark' ? 'bg-transparent border border-gray-600' : 'bg-gray-200'}`}>
                <h2 className="text-3xl font-bold mb-4">Qui sommes-nous ?</h2>
                <p className="mb-6">Découvrez nos intervenants et nos prestations, et ce qui nous démarque de nos concurrents.</p>
                <Link to="/Presentation">
                    <button className="bg-blue-500 hover:bg-blue-400 text-white font-semibold py-2 px-4 rounded">
                        En savoir plus
                    </button>
                </Link>
            </div>

              {/* Section Notre Agence */}
              <div
                className={`notre-agence p-10 text-center ${
                    colorMode === 'dark' ? 'bg-transparent border border-gray-600' : 'bg-gray-100'
                }`}
            >
                <h1 className="text-3xl font-bold text-center mb-6 animate-fade-in">Notre Agence de Services Personnalisés</h1>
                <p className="text-center mb-4 animate-fade-in">Des solutions pour toute votre famille</p>
                <blockquote className="italic text-center mb-6 animate-fade-in">
                    « Grâce à nos services, des centaines de personnes sont en mesure de vivre à domicile en toute sécurité depuis plus de 10 ans, malgré l’âge, la maladie ou le handicap. »
                </blockquote>

               {/* Images avec animation */}
<div className="flex flex-col md:flex-row justify-center items-center gap-6">
    <div className="flex flex-col items-center">
        <img src={image6} alt="Image6" className="w-full max-w-md h-auto animate-fade-in" />
        <div className="text-center p-4 animate-fade-in">
            <h2 className="text-lg sm:text-xl font-semibold">14 ans à votre service pour la santé et le bien-être</h2>
            <p>Nous mettons notre expérience à votre disposition pour des services de qualité.</p>
        </div>
    </div>

    <div className="flex flex-col items-center">
        <img src={image7} alt="Image7" className="w-full max-w-md h-auto animate-fade-in" />
        <div className="text-center p-4 animate-fade-in">
            <h2 className="text-lg sm:text-xl font-semibold">Interventions 24H/7J pour toute la famille</h2>
            <p>Nos équipes sont disponibles à tout moment pour vous aider.</p>
        </div>
    </div>
</div>


                {/* Section Nos Services */}
                {/* Section Nos Services */}
<div className={`mt-10 flex flex-col items-center ${colorMode === 'dark' ? 'border border-gray-600' : ''}`}>
    <div
        className={`p-4 rounded shadow-lg flex items-center cursor-pointer ${
            colorMode === 'dark' ? 'bg-gradient-to-r from-gray-800 to-gray-700' : 'bg-gradient-to-r from-blue-200 to-blue-400'
        }`}
        onClick={toggleServices}
    >
        <h2 className="text-xl font-semibold">Nos Services à Domicile</h2>
        <FaChevronDown className="ml-2" />
    </div>

    {showServices && (
        <div className="mt-4 p-4 w-full sm:w-auto">
            <div className={`rounded shadow-lg p-6 ${colorMode === 'dark' ? 'bg-gray-800' : 'bg-white'}`}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <ul>
                        <li className="mb-2 flex items-center"><FaCheckCircle className="mr-2" />Transport de PMR</li>
                        <li className="mb-2 flex items-center"><FaCheckCircle className="mr-2" />Mesures barrières</li>
                        <li className="mb-2 flex items-center"><FaCheckCircle className="mr-2" />Intervenants spécialisés</li>
                    </ul>
                    <ul>
                        <li className="mb-2 flex items-center"><FaCheckCircle className="mr-2" />Agence certifiée Cap'Handéo</li>
                        <li className="mb-2 flex items-center"><FaCheckCircle className="mr-2" />Service prestataire & mandataire</li>
                        <li className="mb-2 flex items-center"><FaCheckCircle className="mr-2" />Accompagnement en vacances</li>
                    </ul>
                </div>
            </div>
        </div>
    )}
</div>


                <div className="mt-8 text-center animate-fade-in">
                    <h2 className="text-xl font-semibold">Mr Christian Kenne</h2>
                    <p className={`${colorMode === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>Directeur</p>
                </div>
            </div>

           {/* Section Notre Méthodologie */}
           <div
                className={`notre-methodologie p-6 md:p-10 text-center ${
                    colorMode === 'dark' ? 'bg-transparent border border-gray-600' : 'bg-gray-200'
                }`}
            >
                <h1 className="text-3xl font-bold text-center mb-6">Notre Méthodologie de Soutien Familial</h1>
                <p className="text-center mb-4">
                    Nous adoptons une approche personnalisée pour chaque client, afin d'assurer un service de qualité et adapté à vos besoins.
                </p>

                {/* Cards for Methodology */}
                <div className="flex flex-wrap justify-center mt-8">
                    {[{ icon: FaPaperPlane, step: 'Formulaire de demande', desc: 'Remplissez un formulaire simple pour nous faire part de vos besoins.' },
                      { icon: FaClock, step: 'Planification', desc: 'Nous planifions une rencontre pour mieux comprendre vos besoins.' },
                      { icon: FaUser, step: 'Intervenant dédié', desc: 'Un intervenant spécialisé vous est attribué pour un suivi personnalisé.' }
                    ].map((card, index) => (
                        <div
                            key={index}
                            className={`p-6 rounded shadow-lg text-center relative max-w-xs mx-4 mb-6 transition-transform transform hover:shadow-xl hover:scale-105 overflow-hidden ${
                                colorMode === 'dark' ? 'bg-gray-800 border-gray-700' : 'bg-white'
                            }`}
                        >
                            <div className="absolute top-2 left-2 bg-yellow-500 text-white rounded-full w-8 h-8 flex items-center justify-center font-bold">
                                {index + 1}
                            </div>
                            <card.icon className="text-4xl text-yellow-500 mb-4 mx-auto" />
                            <h3 className="text-xl font-semibold mb-2">{card.step}</h3>
                            <p>{card.desc}</p>
                            <div
                                className={`absolute inset-0 bg-gradient-to-b from-transparent to-blue-200 transition-all duration-300 transform translate-y-full hover:translate-y-0 ${
                                    colorMode === 'dark' ? 'bg-gradient-to-b from-transparent to-gray-700' : ''
                                }`}
                            ></div>
                        </div>
                    ))}
                </div>

                <div className="mt-10 text-center">
                    <Link to="/demande-de-devis">
                        <button className="bg-yellow-500 hover:bg-yellow-400 text-black font-semibold py-2 px-6 rounded transition duration-300">
                            Devis
                        </button>
                    </Link>
                </div>
            </div>

             {/* Section des Blogs récents */}
             <div className="flex flex-col gap-6 mt-10">
            {recentBlogs.length > 0 ? (
                recentBlogs.map(blog => (
                    <div
                        key={blog.id}
                        className={`rounded-lg shadow-lg flex transform transition-transform duration-300 hover:shadow-xl hover:scale-105 overflow-hidden ${colorMode === 'dark' ? 'bg-gray-800 border border-gray-700' : 'bg-white'}`}
                    >
                        {blog.image ? (
                            <img
                                src={`https://www.hisvie.com/backend/${blog.image}`}
                                alt={blog.title}
                                className="w-64 h-48 object-cover"
                            />
                        ) : (
                            <div className="w-64 h-48 bg-gray-200 flex items-center justify-center">
                                <p className="text-gray-400">Aucune image disponible</p>
                            </div>
                        )}
                        <div className="flex flex-col justify-between p-4 h-48">
                            <SafeContent htmlContent={blog.title} />
                            <SafeContent htmlContent={blog.description} />
                            <Link to={`/blog/${blog.id}`} className="text-blue-500 hover:underline">
                                Lire la suite
                            </Link>
                        </div>
                    </div>
                ))
            ) : (
                <p className="text-center">Aucun blog disponible.</p>
            )}
        </div>

        </>
    );
}

export default Home;