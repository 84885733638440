import React from "react";
import Image7 from '../../../Asset/image7.png';
import { FaMoneyBillWave, FaHandHoldingHeart, FaFileInvoice, FaCalendarAlt, FaUsers } from "react-icons/fa";
import { Helmet } from 'react-helmet';
import { useColorMode } from '@chakra-ui/react';

const AideFinanciere = () => {
    const { colorMode } = useColorMode(); // Récupération du mode couleur

    const aides = [
        {
            title: "Aide aux personnes âgées (APA)",
            description: "L’APA est une allocation destinée aux personnes âgées de 60 ans et plus en perte d’autonomie. La demande s’effectue auprès du conseil départemental. Il n’y a pas de conditions de revenu pour en bénéficier.",
            icon: <FaUsers className="text-4xl text-blue-500" />,
            link: "https://www.service-public.fr/particuliers/vosdroits/N392#:~:text=Une%20personne%20%C3%A2g%C3%A9e%20peut%20b%C3%A9n%C3%A9ficier,l%C3%A9gal%20de%20d%C3%A9part%20%C3%A0%20la"
        },
        {
            title: "Allocation aux adultes handicapés (AAH)",
            description: "L’allocation aux adultes handicapés (AAH) est une aide financière qui vous permet d’avoir un minimum de ressources. Elle est accordée sur décision de la CDAPH.",
            icon: <FaHandHoldingHeart className="text-4xl text-blue-500" />,
            link: "https://www.service-public.fr/particuliers/vosdroits/F12242"
        },
        {
            title: "Déduction fiscale",
            description: "Les sommes versées au titre de l’aide à domicile à HIS peuvent faire l’objet d’une déduction fiscale à concurrence de 50% des sommes engagées dans la limite de 15 000 €.",
            icon: <FaFileInvoice className="text-4xl text-blue-500" />,
            link: "https://www.service-public.fr/particuliers/vosdroits/F823"
        },
        {
            title: "Allocation de solidarité aux personnes âgées (Aspa)",
            description: "L’Aspa est une prestation mensuelle accordée aux retraités ayant de faibles ressources et vivant en France. Son montant dépend de votre situation familiale.",
            icon: <FaCalendarAlt className="text-4xl text-blue-500" />,
            link: "https://www.service-public.fr/particuliers/vosdroits/F16871"
        },
        {
            title: "Majoration pour la vie autonome (MVA)",
            description: "La MVA est une aide financière qui peut s’ajouter à l’AAH. Elle permet de faire face aux dépenses liées à votre handicap.",
            icon: <FaMoneyBillWave className="text-4xl text-blue-500" />,
            link: "https://www.service-public.fr/particuliers/vosdroits/F12903"
        },
    ];

    return (
        <>
            <Helmet>
            <title>Aide financière - Hetep Iaout Services</title>
                <link rel="canonical" href="https://www.hisvie.com/aide-financiere/" />
                <meta property="og:locale" content="fr_FR" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Aide financière - Hetep Iaout Services" />
                <meta property="og:description" content="Aide financière Dernières annonces gouvernementales Covid Conseils pratiques Actualité HIS Solutions financières Des difficultés financières? Sachez que des solutions existent et que vous pouvez bénéficier de plusieurs types d&rsquo;aides afin de vous permettre de faire face à vos charges. Appelez-nous pour en parler. Nous vous proposerons des solutions.  01 43 52 64 23 Programmes de &hellip; Aide financière Lire la suite »" />
                <meta property="og:url" content="https://www.hisvie.com/aide-financiere/" />
                <meta property="og:site_name" content="Hetep Iaout Services" />
                <meta property="article:modified_time" content="2024-09-30T13:57:15+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:label1" content="Durée de lecture estimée" />
                <meta name="twitter:data1" content="4 minutes" />
                <meta name="keywords" content="aide financière, APA, AAH, Aspa, MVA, CESU, aides aux personnes âgées, allocation handicapés, déduction fiscale, chèque emploi service, services handicap" />
            </Helmet>

            {/* Bannière */}
            <div className={`relative w-full h-72 mb-0 bg-cover bg-center ${colorMode === 'dark' ? 'bg-gray-800' : 'bg-gray-100'}`} style={{ backgroundImage: `url(${Image7})` }}>
                <div className="flex flex-col items-center justify-center h-full bg-black bg-opacity-50 text-center">
                    <h1 className="text-4xl font-bold text-white mb-2">Aides Financières pour les Personnes Âgées et Handicapées</h1>
                    <p className="text-lg text-white px-4">
                        Des difficultés financières? Sachez que des solutions existent et que vous pouvez bénéficier de plusieurs types d’aides.
                        Appelez-nous pour en parler. Nous vous proposerons des solutions.
                    </p>
                </div>
            </div>

            <div className={`max-w-6xl mx-auto p-4 sm:p-6 ${colorMode === 'dark' ? 'bg-gray-800' : 'bg-white'} ${colorMode === 'dark' ? 'text-gray-300' : 'text-gray-900'}`}>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {aides.map((aide, index) => (
                        <div key={index} className={`border rounded-lg shadow-lg p-6 transition-shadow duration-300 ${colorMode === 'dark' ? 'bg-gray-700' : 'bg-white'} ${colorMode === 'dark' ? 'text-gray-300' : 'text-gray-900'}`}>
                            <div className="flex items-center mb-4">
                                {aide.icon}
                                <h2 className="text-xl font-semibold ml-2">{aide.title}</h2>
                            </div>
                            <p>{aide.description}</p>
                            <div className="mt-4">
                                <a
                                    href={aide.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-300"
                                >
                                    En savoir plus
                                </a>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Section CESU dans une carte */}
                <div className="mt-8">
                    <div className={`border rounded-lg shadow-lg p-6 transition-shadow duration-300 ${colorMode === 'dark' ? 'bg-gray-700' : 'bg-gray-100'} ${colorMode === 'dark' ? 'text-gray-300' : 'text-gray-900'}`}>
                        <h2 className="text-2xl font-bold mb-4 text-center">Facilité Financière avec le CESU</h2>
                        <p className="mb-2 text-center">
                            Le chèque emploi service (CESU) préfinancé nouvellement mis à disposition des entreprises, collectivités, administrations, 
                            caisses de retraite… pourra vous permettre de régler facilement.
                        </p>
                        <p className="text-center">
                            Nos conseils vous permettront de bénéficier de certaines aides. Des déductions fiscales vous seront appliquées.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AideFinanciere;
