import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import BlogFilter from '../blogFilter/blogFilter';
import { Helmet } from 'react-helmet';
import { useColorMode } from '@chakra-ui/react';
import { AiFillHeart, AiFillDelete } from 'react-icons/ai'; // Importer les icônes
import SafeContent from '../Securite/safeContent'; // Utilisation pour nettoyer et afficher HTML

const BlogPage = () => {
    const { colorMode } = useColorMode();
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [likedBlogs, setLikedBlogs] = useState(new Set());
    const [isAdmin, setIsAdmin] = useState(false);
    const [filter, setFilter] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [sortBy, setSortBy] = useState('');

    // Fonction pour récupérer les blogs
    const fetchBlogs = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://www.hisvie.com/backend/api/blog');
            if (response.status === 200) {
                setBlogs(response.data);
                const liked = JSON.parse(localStorage.getItem('likedBlogs')) || [];
                setLikedBlogs(new Set(liked));
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des blogs:', error);
        } finally {
            setLoading(false);
        }
    };

    // Vérification du statut d'admin
    const checkAdminStatus = () => {
        const token = localStorage.getItem('token');
        if (!token) return false;
        try {
            const decoded = JSON.parse(atob(token.split('.')[1]));
            return !!decoded.adminId;
        } catch (error) {
            console.error("Erreur lors de l'analyse de l'utilisateur :", error);
            return false;
        }
    };

    useEffect(() => {
        fetchBlogs();
        setIsAdmin(checkAdminStatus());
    }, []);

    // Fonction pour gérer les likes
    const toggleLike = async (blogId) => {
        const token = localStorage.getItem('token');
        try {
            const isLiked = likedBlogs.has(blogId);
            const newLikedBlogs = new Set(likedBlogs);

            if (isLiked) {
                newLikedBlogs.delete(blogId);
                await axios.patch(`https://www.hisvie.com/backend/api/blog/${blogId}/unlike`, {}, {
                    headers: { Authorization: `Bearer ${token}` }
                });
            } else {
                newLikedBlogs.add(blogId);
                await axios.patch(`https://www.hisvie.com/backend/api/blog/${blogId}/like`, {}, {
                    headers: { Authorization: `Bearer ${token}` }
                });
            }

            setLikedBlogs(newLikedBlogs);
            localStorage.setItem('likedBlogs', JSON.stringify([...newLikedBlogs]));
            updateBlogLikes(blogId, isLiked);
        } catch (error) {
            console.error('Erreur lors de la mise à jour des likes:', error);
        }
    };

    // Fonction pour mettre à jour les likes affichés
    const updateBlogLikes = (blogId, isLiked) => {
        setBlogs((prevBlogs) =>
            prevBlogs.map((blog) =>
                blog.id === blogId
                    ? { ...blog, likes: isLiked ? Math.max(blog.likes - 1, 0) : (blog.likes || 0) + 1 }
                    : blog
            )
        );
    };

    // Fonction pour supprimer un blog
    const deleteBlog = async (blogId) => {
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`https://www.hisvie.com/backend/api/blog/${blogId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setBlogs((prevBlogs) => prevBlogs.filter(blog => blog.id !== blogId));
        } catch (error) {
            console.error('Erreur lors de la suppression du blog:', error);
        }
    };

    // Filtrage et tri des blogs
    const filteredBlogs = blogs
        .filter(blog => {
            if (filter.length === 0) return true;
            const title = blog.title || '';
            const description = blog.description || '';
            return title.toLowerCase().includes(filter.toLowerCase()) || description.toLowerCase().includes(filter.toLowerCase());
        })
        .filter(blog => {
            const blogDate = new Date(blog.createdAt);
            const now = new Date();
            if (selectedDate === 'last24') {
                return (now - blogDate) <= 24 * 60 * 60 * 1000;
            } else if (selectedDate === 'last3days') {
                return (now - blogDate) <= 3 * 24 * 60 * 60 * 1000;
            } else if (selectedDate === 'lastWeek') {
                return (now - blogDate) <= 7 * 24 * 60 * 60 * 1000;
            } else if (selectedDate === 'lastMonth') {
                return (now - blogDate) <= 30 * 24 * 60 * 60 * 1000;
            }
            return true;
        })
        .sort((a, b) => {
            if (sortBy === 'recent' || sortBy === '') {
                return new Date(b.createdAt) - new Date(a.createdAt);
            } else if (sortBy === 'older') {
                return new Date(a.createdAt) - new Date(b.createdAt);
            } else if (sortBy === 'likes') {
                return b.likes - a.likes;
            }
            return 0;
        });

    return (
        <>
        <Helmet>
            <title>Blog - Hetep Iaout Services</title>
        </Helmet>

        <div className={`max-w-7xl mx-auto px-6 py-10 ${colorMode === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
            <h1 className="text-4xl font-extrabold text-center mb-10">Blog de Hetep Iout Service</h1>
            
            {loading ? (
                <p className="text-center text-gray-500">Chargement des blogs...</p>
            ) : (
                <>
                    {isAdmin && (
                        <Link to="/ajouter-article" className="bg-blue-500 text-white px-4 py-2 rounded-lg mb-4 inline-block hover:bg-blue-600 transition-colors duration-200">
                            Ajouter un article
                        </Link>
                    )}
                    
                    <BlogFilter
                        filter={filter}
                        onFilterChange={(e) => setFilter(e.target.value)}
                        selectedDate={selectedDate}
                        onDateChange={(e) => setSelectedDate(e.target.value)}
                        sortByLikes={sortBy === 'likes'}
                        onSortChange={(e) => setSortBy(e.target.value)}
                    />

                    {filteredBlogs.length > 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
                            {filteredBlogs.map((blog) => (
                                <div key={blog.id} className={`bg-white shadow-md rounded-lg overflow-hidden transform transition hover:scale-105 duration-300 ease-in-out ${colorMode === 'dark' ? 'bg-gray-700' : 'bg-white'}`}>
                                    <Link to={`/blog/${blog.id}`} className="block no-underline hover:no-underline">
                                        {blog.image ? (
                                            <img
                                                src={`https://www.hisvie.com/backend/${blog.image}`}
                                                alt="Blog"
                                                className="w-full h-48 object-cover"
                                            />
                                        ) : (
                                            <div className="w-full h-48 bg-gray-200 flex items-center justify-center">
                                                <p className="text-gray-400">Aucune image disponible</p>
                                            </div>
                                        )}
                                        <div className={`p-6 ${colorMode === 'dark' ? 'bg-gray-700 text-gray-300' : 'bg-white text-gray-900'}`}>
                                            <h1 className="text-xl font-semibold mb-2">
                                                <SafeContent htmlContent={blog.title} />
                                            </h1>
                                            <p className="text-gray-600 mb-2">
                                                <SafeContent htmlContent={blog.description ? blog.description.substring(0, 100) : "Pas de description disponible"} />
                                            </p>
                                            <p className="text-gray-500 text-sm mb-2">
                                                {new Date(blog.createdAt).toLocaleDateString('fr-FR', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric'
                                                })} à {new Date(blog.createdAt).toLocaleTimeString('fr-FR', {
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                })}
                                            </p>
                                            <p className="text-blue-500 font-bold">Likes: {blog.likes || 0}</p>
                                        </div>
                                    </Link>
                                    <div className={`px-6 pb-6 flex justify-between items-center ${colorMode === 'dark' ? 'bg-gray-700' : '' }`}>
                                        <button
                                            className={`border border-solid ${colorMode === 'dark' ? 'border-gray-300 text-gray-300 hover:bg-gray-700' : 'border-gray-800 text-gray-800 hover:bg-gray-200'} px-4 py-2 rounded-lg transition-colors duration-200`}
                                            onClick={() => toggleLike(blog.id)}>
                                            <AiFillHeart className={`${likedBlogs.has(blog.id) ? 'text-red-500' : 'text-gray-500'}`} size={20} />
                                        </button>
                                        {isAdmin && (
                                            <button
                                                className={`px-4 py-2 rounded-lg ${colorMode === 'dark' ? 'bg-red-600 text-white hover:bg-red-700' : 'bg-red-500 text-white hover:bg-red-600'} transition-colors duration-200`}
                                                onClick={() => deleteBlog(blog.id)}>
                                                <AiFillDelete size={20} />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-center text-gray-500 mt-8">Aucun blog trouvé.</p>
                    )}
                </>
            )}
        </div>
        </>
    );
};

export default BlogPage;
