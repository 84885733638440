import React from 'react';
import { FaChild, FaBaby, FaWheelchair, FaUserNurse, FaHandsHelping, FaSchool } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { useColorMode } from '@chakra-ui/react';
import image1 from '../../../Asset/image13.jpg';
import ImageA from '../../../Asset/image11.jpg';

const GardeEnfants = () => {
  const { colorMode } = useColorMode();
  
  const services = [
    {
      title: 'Enfants de plus de 3 ans',
      description: 'Notre intervenant prend soin de votre enfant à votre domicile, assurant notamment la gestion du linge, ainsi que la préparation de ses repas.',
      icon: <FaChild className="text-7xl text-blue-600" />,
    },
    {
      title: 'Enfants de moins de 3 ans',
      description: 'Un intervenant spécialisé veille sur votre enfant en votre absence. Vous pourrez ainsi vaquer à vos impératifs professionnels en toute quiétude.',
      icon: <FaBaby className="text-7xl text-pink-600" />,
    },
    {
      title: 'Enfants en situation de handicap',
      description: 'Quelque soit le handicap de votre enfant, un intervenant spécialisé veille sur lui et contribue à son épanouissement.',
      icon: <FaWheelchair className="text-7xl text-green-600" />,
    },
    {
      title: 'Babysitting',
      description: 'Vous faites face à un impondérable. Nous vous proposons une babysitter dans des délais très courts.',
      icon: <FaUserNurse className="text-7xl text-purple-600" />,
    },
    {
      title: 'Complément de mode de garde',
      description: 'L\'emploi du temps de votre crèche ou de votre assistante maternelle ne s\'accorde pas avec vos disponibilités. Nous vous proposons un complément à votre mode de garde habituel.',
      icon: <FaHandsHelping className="text-7xl text-yellow-600" />,
    },
    {
      title: 'Sorties de crèche ou de classe',
      description: 'Des contraintes professionnelles vous empêchent de respecter les horaires de crèche ou de classe de votre enfant ? Un intervenant s\'en charge à votre place.',
      icon: <FaSchool className="text-7xl text-orange-600" />,
    },
  ];

  return (
    <>
      <Helmet>
      <title>Enfants - Hetep Iaout Services</title>
        <link rel="canonical" href="https://www.hisvie.com/enfants/" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Enfants - Hetep Iaout Services" />
        <meta property="og:description" content="Enfants Accueil Enfants Garde d&#039;enfants Faites garder vos enfants à domicile Vous n&rsquo;êtes pas en mesure de garder votre enfant du fait d&rsquo;un impondérable ou d&rsquo;une situation plus durable ? Vos horaires professionnels sont incompatibles avec leurs sorties de classe ? Quelque soient les situations que vous rencontrez, nous avons des solutions à vous proposer. &hellip; Enfants Lire la suite »" />
        <meta property="og:url" content="https://www.hisvie.com/enfants/" />
        <meta property="og:site_name" content="Hetep Iaout Services" />
        <meta property="article:modified_time" content="2024-09-30T13:52:12+00:00" />
        <meta property="og:image" content="https://www.hisvie.com/wp-content/uploads/2021/06/selective-focus-of-attractive-babysitter-holding-notebook-near-cute-child-studying-at-home.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Durée de lecture estimée" />
        <meta name="twitter:data1" content="4 minutes" />
        <meta name="keywords" content="Garde d'enfants, garde à domicile, babysitting, enfants en situation de handicap, solution de garde, intervenant spécialisé, sortie de crèche, Hetep Iout Service, complément mode de garde, Paris, services pour enfants" />
      </Helmet>

      {/* Bannière */}
      <div className="banniereOne flex flex-col items-center justify-center text-white p-6 sm:p-8 md:p-10 h-[70vh] relative">
    <img src={image1} alt="Bannière" className="absolute inset-0 object-cover w-full h-full filter brightness-50" />
    <div className="relative z-10 text-center px-4 sm:px-8 md:px-10">
        <h1 className="text-3xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-4">
            Garde d'Enfants à Domicile - Solution Adaptée et Fiable
        </h1>
        <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-4 sm:mb-6">
            Des Services sur Mesure pour la Tranquillité des Parents
        </h2>
        <p className="text-sm sm:text-lg mb-4 sm:mb-6">
            Une solution de garde fiable et adaptée à vos besoins
        </p>
        <Link to="/demande-de-devis">
            <button className="bg-yellow-500 hover:bg-yellow-400 text-black font-semibold py-2 px-6 rounded transition duration-300">
                Obtenir un devis
            </button>
        </Link>
    </div>
</div>


      <div className="container mx-auto font-sans">
        {/* Titre des services */}
        <h2 className={`text-3xl font-bold text-center my-8 ${colorMode === 'dark' ? 'text-white' : 'text-gray-900'}`}>Nos Services de Garde d'Enfants</h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          {services.map((service, index) => (
            <div 
              key={index} 
              className={`border rounded-lg overflow-hidden shadow-lg transition-all duration-300 transform hover:scale-105 hover:shadow-2xl ${colorMode === 'dark' ? 'bg-gray-800 border-gray-700' : 'bg-gray-50 border-gray-200'}`}
            >
              <div className={`flex justify-center items-center h-48 ${colorMode === 'dark' ? 'bg-gray-700' : 'bg-gray-100'}`}>
                {service.icon}
              </div>
              <div className="p-4">
                <h3 className={`text-lg font-semibold ${colorMode === 'dark' ? 'text-white' : 'text-gray-900'}`}>{service.title}</h3>
                <p className={`mt-2 ${colorMode === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>{service.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Section Solution Sérénité */}
        <div className="flex flex-col items-center mt-24">
          <h2 className={`text-2xl underline mb-12 text-center ${colorMode === 'dark' ? 'text-white' : 'text-gray-900'}`}>Solution Sérénité</h2>
          <h1 className={`text-4xl font-semibold italic mb-12 text-center ${colorMode === 'dark' ? 'text-white' : 'text-gray-900'}`}>Des Services de Garde sur Mesure pour un Confort Optimal</h1>
          <div className="flex items-start gap-8">
            <img 
              src={ImageA} 
              alt="Image_Solution_Sérénité" 
              className="w-1/2 h-auto rounded-lg" 
            />
            <div className="w-1/2">
              <p className={`mb-4 ${colorMode === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                Notre formule de garde vous garantit une sérénité optimale. Votre intervenant s’adapte aux besoins de votre enfant, tant en termes d’horaires, qu’en termes de services. Le ménage, en passant par la gestion du linge ou encore la préparation des repas sont pris en charge. Votre enfant participe à des activités extérieures ? L’intervenant l’y conduit et veille sur lui.
              </p>
            </div>
          </div>
        </div>
        
        {/* Section Contact */}
        <div className={`p-8 rounded-lg mt-12 shadow-md text-center ${colorMode === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-gray-100 text-gray-900'} mb-12`}>
          <h2 className={`text-2xl font-semibold mb-4 ${colorMode === 'dark' ? 'text-white' : 'text-gray-900'}`}>Contactez-nous pour en Savoir Plus</h2>
          <p className={`text-lg mb-6 ${colorMode === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
            Pour toute demande d'information ou pour discuter de vos besoins, n'hésitez pas à nous contacter !
          </p>
          <Link to="/contact">
            <button className="bg-yellow-500 hover:bg-yellow-400 text-black font-semibold py-2 px-6 rounded transition duration-300">
              Contactez-nous
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default GardeEnfants;
