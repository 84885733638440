import React, { useState } from 'react';
import { FaCar, FaHospital, FaShoppingCart, FaTheaterMasks } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useColorMode } from '@chakra-ui/react';

const TransportPMR = () => {
  const { colorMode } = useColorMode();
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const examples = [
    {
      title: "Courses et sorties culturelles",
      icon: <FaShoppingCart className="h-8 w-8 text-blue-500" />,
      description: "Profitez de nos services pour vos courses quotidiennes ou vos sorties culturelles.",
    },
    {
      title: "Rendez-vous médicaux et administratifs",
      icon: <FaHospital className="h-8 w-8 text-blue-500" />,
      description: "Nous vous transportons à vos rendez-vous médicaux et administratifs en toute sécurité.",
    },
    {
      title: "Transport de longue distance",
      icon: <FaCar className="h-8 w-8 text-blue-500" />,
      description: "Organisez vos voyages, vacances et déplacements en dehors de l’Ile de France.",
    },
    {
      title: "Activités de loisirs",
      icon: <FaTheaterMasks className="h-8 w-8 text-blue-500" />,
      description: "Nous vous accompagnons à vos activités de loisir pour que vous ne manquiez rien.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Transport de PMR - Hetep Iaout Services</title>
        <link rel="canonical" href="https://www.hisvie.com/transport-de-pmr/" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Transport de PMR - Hetep Iaout Services" />
        <meta property="og:description" content="Transport de PMR Accueil Transport de PMR RESERVER Transport de PMR* Nous mettons à votre disposition une flotte de véhicules dédiés au transport de PMR. Que vous souhaitiez vous déplacer seul ou en groupe, nous sommes en mesure de vous fournir les moyens de transports adéquats. Nos chauffeurs sont formés au transport de personnes à &hellip; Transport de PMR Lire la suite »" />
        <meta property="og:url" content="https://www.hisvie.com/transport-de-pmr/" />
        <meta property="og:site_name" content="Hetep Iaout Services" />
        <meta property="article:modified_time" content="2022-05-16T19:34:41+00:00" />
        <meta property="og:image" content="https://www.hisvie.com/wp-content/uploads/2021/07/Trafic-300x233.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Durée de lecture estimée" />
        <meta name="twitter:data1" content="1 minute" />
        <meta name="keywords" content="Transport PMR, personnes à mobilité réduite, services de transport, rendez-vous médicaux, transport longue distance, loisirs, réservation transport PMR, chauffeurs formés, véhicule PMR, aide à domicile, déplacements médicaux" />
      </Helmet>

      <div className={`flex flex-col items-center justify-center min-h-screen p-5 ${colorMode === 'dark' ? 'bg-gray-900 text-gray-200' : 'bg-gray-100 text-gray-900'}`}>
        {/* Titre principal */}
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-blue-500 mb-4 text-center">
          Services de Transport pour Personnes à Mobilité Réduite
        </h1>
        {/* Petite description sous le titre */}
        <p className="text-base sm:text-lg mb-6 text-center max-w-2xl">
          Découvrez nos services de transport dédiés aux personnes à mobilité réduite, conçus pour faciliter vos déplacements au quotidien.
        </p>

        {/* Conteneur principal */}
        <div className="flex flex-col md:flex-row justify-between w-full max-w-5xl space-y-6 md:space-y-0 md:space-x-6">
          {/* Section des exemples */}
          <div className={`shadow-lg rounded-lg p-6 w-full md:w-1/2 ${colorMode === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-800'}`}>
            <h3 className="text-xl sm:text-2xl font-semibold text-blue-500 mb-4">Exemples de Sorties et Déplacements</h3>
            <ul className="space-y-2">
              {examples.map((example, index) => (
                <li key={index} className="cursor-pointer">
                  <div 
                    className={`flex items-center justify-between p-4 rounded-lg transition duration-200 ${colorMode === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}
                    onClick={() => toggleAccordion(index)}
                  >
                    <div className="flex items-center">
                      {example.icon}
                      <span className="ml-2 font-semibold">{example.title}</span>
                    </div>
                  </div>
                  {openIndex === index && (
                    <div className={`pl-10 pt-2 transition duration-200 ease-in-out ${colorMode === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
                      {example.description}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>

          {/* Section avec le reste du contenu */}
          <div className={`flex flex-col shadow-lg rounded-lg p-6 w-full md:w-1/2 ${colorMode === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
            {/* Section de réservation */}
            <h2 className="text-xl sm:text-3xl font-semibold text-blue-500 mb-4">Réservation de Transport PMR</h2>
            <p className="text-sm sm:text-base mb-4">
              Nous mettons à votre disposition une flotte de véhicules dédiée au transport des personnes à mobilité réduite. Que vous souhaitiez vous déplacer seul ou en groupe, nos chauffeurs formés sont prêts à vous accompagner pour vos rendez-vous médicaux, déplacements chez votre kiné, ou autres besoins de mobilité.
            </p>

            {/* Service conventionné */}
            <div className="mt-6">
              <h3 className="text-lg sm:text-2xl font-semibold text-blue-500 mb-4">Service Conventionné par la CPAM</h3>
              <p className="text-sm sm:text-base">
                Notre service de transport PMR est conventionné par la CPAM pour certains déplacements médicaux. Les autres types de déplacements font l’objet d’une tarification majorée.
              </p>
            </div>

            {/* Bouton de réservation */}
            <div className="text-center mt-6">
              <Link to='/contact' className="bg-blue-400 text-white py-3 px-6 rounded-lg hover:bg-blue-500 transition-colors duration-300 shadow-md">
                Contactez-nous
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransportPMR;
