import React from 'react';
import DOMPurify from 'dompurify';

const SafeContent = ({ htmlContent }) => {
    const cleanHTML = DOMPurify.sanitize(htmlContent, {
        ALLOWED_TAGS: [
            'b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'p', 'br', 
            'div', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
        ],
        ALLOWED_ATTR: ['href', 'target', 'rel', 'class', 'id', 'style'],
    });

    return (
        <div
            dangerouslySetInnerHTML={{
                __html: cleanHTML
                    .replace(/<ul>/g, '<ul class="list-disc list-inside ml-4">')
                    .replace(/<ol>/g, '<ol class="list-decimal list-inside ml-4">'),
            }}
        />
    );
};

export default SafeContent;
