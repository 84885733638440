import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const RessourceForm = () => {
    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!title || !file) {
            setError('Veuillez fournir un titre et sélectionner un fichier.');
            return;
        }

        const formData = new FormData();
        formData.append('title', title);  // Titre de la ressource
        formData.append('file', file);    // Fichier principal
        if (image) {
            formData.append('image', image); // Image optionnelle
        }

        try {
            setLoading(true);
            await axios.post('http://www.hisvie.com/backend/api/ressources/ajouter', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setLoading(false);
            navigate('/NosRessources');
        } catch (error) {
            setLoading(false);
            const errorMessage = error.response?.data?.message || 'Erreur lors de l\'ajout de la ressource. Veuillez réessayer.';
            setError(errorMessage);
            console.error('Erreur lors de l\'ajout de la ressource:', errorMessage);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="p-6 max-w-md mx-auto bg-white shadow-md rounded-md">
            <h1 className="text-2xl font-bold mb-4">Ajouter une Ressource</h1>
            {error && <p className="text-red-500 mb-4">{error}</p>}

            <label className="block mb-2 font-semibold">Titre de la ressource :</label>
            <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Titre de la ressource"
                className="w-full p-2 mb-4 border border-gray-300 rounded-md"
                required
            />

            <label className="block mb-2 font-semibold">Sélectionner le fichier :</label>
            <input
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                className="w-full p-2 mb-4 border border-gray-300 rounded-md"
                required
            />

            <label className="block mb-2 font-semibold">Sélectionner une image d'illustrations:</label>
            <input
                type="file"
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
                className="w-full p-2 mb-4 border border-gray-300 rounded-md"
            />

            <button
                type="submit"
                className="w-full bg-blue-600 text-white p-2 rounded-md hover:bg-blue-500 transition duration-300"
                disabled={loading}
            >
                {loading ? 'Ajout en cours...' : 'Ajouter'}
            </button>
        </form>
    );
};

export default RessourceForm;
