import React, { useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useColorMode } from "@chakra-ui/react";

const Devis = () => {
    const { colorMode } = useColorMode();
    const [formData, setFormData] = useState({
        nom: "",
        prenom: "",
        email: "",
        telephone: "",
        message: "",
        service: "",
        handicap: false,
        maladie: false,
        maladieRare: false,
        dependant: false,
        date: "",
        time: "",
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Construisez dynamiquement le contenu HTML
        const htmlContent = `
            <h1>Nouvelle Demande de Devis</h1>
            <p><strong>Nom:</strong> ${formData.nom}</p>
            <p><strong>Prénom:</strong> ${formData.prenom}</p>
            <p><strong>Email:</strong> ${formData.email}</p>
            <p><strong>Téléphone:</strong> ${formData.telephone}</p>
            <p><strong>Message:</strong> ${formData.message}</p>
            <p><strong>Service:</strong> ${formData.service}</p>
        `;
    
        try {
            const response = await axios.post("https://www.hisvie.com/backend/api/email/send-email", {
                senderEmail: "demandedevis@his.com", // Email de l'expéditeur
                receiverEmail: "his@donnerlavieauxannees.com", // Email du destinataire
                subject: "Demande de devis", // Objet de l'email
                htmlContent, // Contenu HTML généré dynamiquement
            });
    
            if (response.status === 201) {
                toast.success("Le formulaire a été envoyé avec succès !");
                setFormData({
                    nom: "",
                    prenom: "",
                    email: "",
                    telephone: "",
                    message: "",
                    service: "",
                });
            }
        } catch (error) {
            console.error("Erreur lors de l'envoi du formulaire :", error);
            toast.error("Une erreur est survenue lors de l'envoi du formulaire.");
        }
    };
    

    return (
        <>
            <Helmet>
                <title>Demande de devis - Hetep Iaout Services</title>
                <link rel="canonical" href="https://www.hisvie.com/demande-de-devis/" />
                <meta property="og:locale" content="fr_FR" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Demande de devis - Hetep Iaout Services" />
                <meta property="og:description" content="Devis Accueil Devis Formulaire de demande de devis Devis personnalisé Vos besoins sont spécifiques ? Pas de problème. Remplissez dès maintenant le formulaire ci-dessous et un conseiller prendra contact avec vous selon vos disponibilités. Demande de devis Obtenir un devis gratuitement Remplissez le plus précisément possible le formulaire ci-dessous. Il est possible que nous soyons &hellip; Demande de devis Lire la suite »" />
                <meta property="og:url" content="https://www.hisvie.com/demande-de-devis/" />
                <meta property="og:site_name" content="Hetep Iaout Services" />
                <meta property="article:modified_time" content="2022-05-16T19:36:12+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="keywords" content="devis, aide à domicile, handicap, maladies rares, dépendance" />
            </Helmet>
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar />

            <div className={`devis-container max-w-4xl mx-auto p-4 sm:p-8 ${colorMode === 'dark' ? 'bg-gray-800' : 'bg-white'} shadow-lg rounded-lg mt-6 mb-10`}>
                <h1 className={`text-2xl sm:text-3xl font-bold text-center mb-6 ${colorMode === 'dark' ? 'text-white' : 'text-gray-800'}`}>Demande de Devis</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <label className="block">
                        <span className={`text-gray-700 ${colorMode === 'dark' ? 'text-white' : 'text-gray-700'}`}>Nom:</span>
                        <input
                            type="text"
                            name="nom"
                            value={formData.nom}
                            onChange={handleChange}
                            required
                            className={`mt-1 block w-full p-2 sm:p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300 ${colorMode === 'dark' ? 'bg-gray-900 text-white' : 'bg-white'}`}
                        />
                    </label>
                    <label className="block">
                        <span className={`text-gray-700 ${colorMode === 'dark' ? 'text-white' : 'text-gray-700'}`}>Prénom:</span>
                        <input
                            type="text"
                            name="prenom"
                            value={formData.prenom}
                            onChange={handleChange}
                            required
                            className={`mt-1 block w-full p-2 sm:p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300 ${colorMode === 'dark' ? 'bg-gray-900 text-white' : 'bg-white'}`}
                        />
                    </label>
                    <label className="block">
                        <span className={`text-gray-700 ${colorMode === 'dark' ? 'text-white' : 'text-gray-700'}`}>Email:</span>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className={`mt-1 block w-full p-2 sm:p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300 ${colorMode === 'dark' ? 'bg-gray-900 text-white' : 'bg-white'}`}
                        />
                    </label>
                    <label className="block">
                        <span className={`text-gray-700 ${colorMode === 'dark' ? 'text-white' : 'text-gray-700'}`}>Téléphone:</span>
                        <input
                            type="tel"
                            name="telephone"
                            value={formData.telephone}
                            onChange={handleChange}
                            required
                            className={`mt-1 block w-full p-2 sm:p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300 ${colorMode === 'dark' ? 'bg-gray-900 text-white' : 'bg-white'}`}
                        />
                    </label>
                    <label className="block">
                        <span className={`text-gray-700 ${colorMode === 'dark' ? 'text-white' : 'text-gray-700'}`}>Message:</span>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                            className={`mt-1 block w-full p-2 sm:p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300 resize-y ${colorMode === 'dark' ? 'bg-gray-900 text-white' : 'bg-white'}`}
                        />
                    </label>
                    <label className="block">
                        <span className={`text-gray-700 ${colorMode === 'dark' ? 'text-white' : 'text-gray-700'}`}>Service:</span>
                        <select
                            name="service"
                            value={formData.service}
                            onChange={handleChange}
                            required
                            className={`mt-1 block w-full p-2 sm:p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300 ${colorMode === 'dark' ? 'bg-gray-900 text-white' : 'bg-white'}`}
                        >
                            <option value="">Sélectionnez un service</option>
                            <option value="aide aux actes essentiels">Aide aux actes essentiels</option>
                            <option value="gardes d'enfants">Gardes d'enfants</option>
                            <option value="ménage de votre logement">Ménage de votre logement</option>
                        </select>
                    </label>
                    
                    <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 mb-4">
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                name="maladie"
                                checked={formData.maladie}
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <span className={`text-gray-700 ${colorMode === 'dark' ? 'text-white' : ''}`}>Maladie</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                name="handicap"
                                checked={formData.handicap}
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <span className={`text-gray-700 ${colorMode === 'dark' ? 'text-white' : ''}`}>Handicap</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                name="maladieRare"
                                checked={formData.maladieRare}
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <span className={`text-gray-700 ${colorMode === 'dark' ? 'text-white' : ''}`}>Maladies rares/orphelines</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                name="dependant"
                                checked={formData.dependant}
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <span className={`text-gray-700 ${colorMode === 'dark' ? 'text-white' : ''}`}>Dépendant</span>
                        </label>
                    </div>
                    
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                        <label className="block">
                            <span className={`text-gray-700 ${colorMode === 'dark' ? 'text-white' : 'text-gray-700'}`}>Date de rendez-vous:</span>
                            <input
                                type="date"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                                required
                                className={`mt-1 block w-full p-2 sm:p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300 ${colorMode === 'dark' ? 'bg-gray-900 text-white' : 'bg-white'}`}
                            />
                        </label>
                        <label className="block">
                            <span className={`text-gray-700 ${colorMode === 'dark' ? 'text-white' : 'text-gray-700'}`}>Heure de rendez-vous:</span>
                            <select
                                name="time"
                                value={formData.time}
                                onChange={handleChange}
                                required
                                className={`mt-1 block w-full p-2 sm:p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300 ${colorMode === 'dark' ? 'bg-gray-900 text-white' : 'bg-white'}`}
                            >
                                <option value="">Sélectionnez une heure</option>
                                <option value="10h - 11h">10h - 11h</option>
                                <option value="11h - 12h">11h - 12h</option>
                                <option value="12h - 13h">12h - 13h</option>
                                <option value="13h - 14h">13h - 14h</option>
                                <option value="14h - 15h">14h - 15h</option>
                                <option value="15h - 16h">15h - 16h</option>
                                <option value="16h - 17h">16h - 17h</option>
                                <option value="17h - 18h">17h - 18h</option>
                                <option value="18h - 19h">18h - 19h</option>
                                <option value="19h - 20h">19h - 20h</option>
                            </select>
                        </label>
                    </div>

                    <button
                        type="submit"
                        className="w-full py-3 bg-blue-400 text-white font-semibold rounded-lg shadow hover:bg-blue-500 transition duration-150 ease-in-out"
                    >
                        Envoyer
                    </button>
                </form>
            </div>
        </>
    );
};

export default Devis;
